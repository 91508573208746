import * as S from "./styles";

import React from "react";

const Footer = () => {
  return (
    <S.Footer>
      <S.FooterSide id="left">{S.FooterLogo}</S.FooterSide>
      <S.FooterCenter>
        <S.FooterCenterContent>
          <span>MEETING ONLINE: Zoom, Skype</span>
        </S.FooterCenterContent>
        <S.FooterCenterContent>
          OFFLINE: 서울특별시 송파구 오금로15길 5-12 5F
          <br />
          5-12 OGEUM-RO 15-GIL, SONGPA-GU, SEOUL
        </S.FooterCenterContent>
        <S.FooterCenterContent>
          대표 번호 <span>070-5101-6200</span>
          <br /> 입점 문의 <span>070-5101-6117</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail <span>justq@justq.co.kr</span>
        </S.FooterCenterContent>
      </S.FooterCenter>
      <S.FooterSide id="right" />
    </S.Footer>
  );
};

export default Footer;
