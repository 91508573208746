import * as S from './styles';

import React from 'react';
import { motion } from 'framer-motion';

const Section5 = () => {
  return (
    <S.Section5>
      <motion.svg
        width="160"
        height="200"
        viewBox="0 0 160 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial={{ x: '100%', opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <path
          d="M20.0421 1.31724C20.4102 1.56252 20.7292 1.88139 20.9255 2.27384C21.1218 2.6663 21.22 3.13233 21.1464 3.6229L20.7292 6.49271L20.312 9.36252L19.8948 12.2323L19.4777 15.1021C19.4777 15.2493 19.4531 15.3965 19.4777 15.5437C19.4777 15.6908 19.5267 15.838 19.5513 16.0097C19.6004 16.1569 19.6494 16.304 19.7231 16.4512C19.7967 16.5984 19.8703 16.721 19.993 16.8682L21.9317 19.3455L23.8703 21.8229L25.809 24.3003L27.7476 26.7776C28.0666 27.1946 28.2384 27.6606 28.2629 28.0776C28.2875 28.4946 28.1893 28.9116 27.993 29.2304C27.7967 29.5493 27.4777 29.8191 27.1096 29.9418C26.7415 30.0644 26.2998 30.0644 25.809 29.8682L19.9194 27.587L14.0298 25.3059L8.14024 23.0248L2.25068 20.7437C1.88258 20.5965 1.56356 20.3757 1.31816 20.0814C1.07276 19.8116 0.900983 19.4682 0.802824 19.1248C0.704664 18.7814 0.680123 18.4135 0.753743 18.0946C0.827362 17.7512 0.974604 17.4569 1.24454 17.1871L5.36724 13.2625L9.48994 9.31346L13.6126 5.38894L17.7353 1.43988C18.0789 1.12101 18.4715 0.973846 18.8642 0.973846C19.2568 0.973846 19.674 1.09649 20.0421 1.34177V1.31724Z"
          fill="white"
        />
        <path
          d="M160.191 98.0569C159.896 98.2776 159.528 98.3512 159.185 98.2531C158.816 98.1795 158.473 97.9588 158.227 97.6154C157.982 97.272 157.859 96.8795 157.884 96.5116C157.884 96.1437 158.056 95.8003 158.35 95.5795C158.645 95.3588 159.013 95.2852 159.356 95.3833C159.724 95.4569 160.068 95.6776 160.313 96.021C160.559 96.3644 160.681 96.7569 160.657 97.1248C160.657 97.4927 160.485 97.8361 160.191 98.0323V98.0569Z"
          fill="white"
        />
        <path
          d="M162.079 89.055C161.76 89.3002 161.343 89.3738 160.95 89.2757C160.557 89.2021 160.165 88.9569 159.87 88.5889C159.576 88.221 159.453 87.7795 159.478 87.3625C159.502 86.9455 159.674 86.5776 159.993 86.3323C160.312 86.087 160.729 86.0135 161.122 86.1116C161.514 86.2097 161.907 86.4304 162.202 86.7984C162.496 87.1663 162.619 87.6078 162.594 88.0248C162.57 88.4418 162.398 88.8097 162.079 89.055Z"
          fill="white"
        />
        <path
          d="M159.895 79.5135C159.552 79.7833 159.086 79.8568 158.668 79.7587C158.251 79.6606 157.81 79.3908 157.491 78.9983C157.172 78.6059 157.049 78.1153 157.073 77.6493C157.098 77.2078 157.294 76.7908 157.638 76.521C157.981 76.2512 158.448 76.1776 158.865 76.2757C159.306 76.3738 159.724 76.6436 160.043 77.0361C160.362 77.4285 160.484 77.9191 160.46 78.3851C160.435 78.8267 160.239 79.2436 159.895 79.5135Z"
          fill="white"
        />
        <path
          d="M155.331 70.5606C154.938 70.855 154.448 70.9285 153.981 70.8304C153.515 70.7323 153.049 70.438 152.705 69.9965C152.362 69.555 152.214 69.0153 152.239 68.5248C152.263 68.0342 152.484 67.5927 152.877 67.2984C153.27 67.004 153.76 66.9304 154.227 67.0285C154.693 67.1267 155.159 67.421 155.503 67.8625C155.846 68.304 155.994 68.8436 155.969 69.3342C155.944 69.8248 155.724 70.2663 155.331 70.5606Z"
          fill="white"
        />
        <path
          d="M149.417 62.3682C149 62.6871 148.46 62.7852 147.945 62.6625C147.429 62.5644 146.914 62.2455 146.546 61.755C146.178 61.2644 146.006 60.7003 146.055 60.1852C146.08 59.6701 146.325 59.1795 146.742 58.8606C147.159 58.5418 147.699 58.4437 148.215 58.5663C148.73 58.6644 149.245 58.9833 149.613 59.4738C149.981 59.9644 150.153 60.5286 150.104 61.0437C150.08 61.5587 149.834 62.0493 149.417 62.3682Z"
          fill="white"
        />
        <path
          d="M142.668 54.8135C142.227 55.1569 141.638 55.255 141.098 55.1323C140.533 55.0097 139.993 54.6663 139.601 54.1512C139.208 53.6361 139.036 53.0229 139.061 52.4588C139.085 51.8946 139.331 51.3795 139.797 51.0361C140.263 50.6927 140.828 50.5946 141.368 50.7172C141.932 50.8399 142.472 51.1833 142.865 51.6984C143.257 52.2135 143.429 52.8267 143.404 53.3908C143.38 53.955 143.135 54.4701 142.668 54.8135Z"
          fill="white"
        />
        <path
          d="M135.379 47.8719C134.889 48.2399 134.275 48.338 133.686 48.2153C133.097 48.0927 132.508 47.7248 132.091 47.1606C131.674 46.5965 131.478 45.9342 131.502 45.3455C131.551 44.7323 131.797 44.1682 132.287 43.8248C132.778 43.4814 133.392 43.3587 133.981 43.4814C134.57 43.604 135.158 43.9719 135.576 44.5361C135.993 45.1002 136.189 45.7625 136.165 46.3512C136.116 46.9644 135.87 47.5286 135.379 47.8719Z"
          fill="white"
        />
        <path
          d="M127.626 41.4946C127.111 41.887 126.448 41.9852 125.835 41.8625C125.221 41.7399 124.583 41.3474 124.117 40.7342C123.651 40.121 123.454 39.4342 123.503 38.7965C123.553 38.1587 123.822 37.5455 124.338 37.1531C124.853 36.7606 125.491 36.6625 126.129 36.7852C126.767 36.9078 127.381 37.3002 127.847 37.9135C128.313 38.5267 128.51 39.2135 128.46 39.8512C128.411 40.4889 128.141 41.1021 127.626 41.4946Z"
          fill="white"
        />
        <path
          d="M119.527 35.6568C118.988 36.0738 118.3 36.1965 117.613 36.0493C116.926 35.9021 116.288 35.5097 115.797 34.8719C115.307 34.2342 115.11 33.4984 115.135 32.8116C115.184 32.1248 115.478 31.487 116.018 31.0701C116.558 30.6531 117.245 30.5304 117.932 30.6776C118.62 30.8248 119.258 31.2172 119.748 31.855C120.239 32.4927 120.435 33.2285 120.411 33.9153C120.362 34.6021 120.067 35.2399 119.527 35.6568Z"
          fill="white"
        />
        <path
          d="M111.134 30.3833C110.545 30.8248 109.834 30.9474 109.098 30.8003C108.386 30.6531 107.674 30.2116 107.184 29.5493C106.693 28.887 106.447 28.0776 106.496 27.3663C106.545 26.6304 106.864 25.9682 107.429 25.5267C107.993 25.0852 108.73 24.9625 109.466 25.1097C110.177 25.2569 110.889 25.6984 111.38 26.3606C111.871 27.0229 112.116 27.8323 112.067 28.5436C112.018 29.2795 111.699 29.9418 111.134 30.3833Z"
          fill="white"
        />
        <path
          d="M102.447 25.7719C101.834 26.238 101.073 26.3606 100.312 26.2135C99.5516 26.0418 98.8154 25.6002 98.2755 24.8889C97.7357 24.1776 97.4903 23.3436 97.5393 22.5833C97.5884 21.8229 97.932 21.1116 98.5209 20.6455C99.1099 20.1795 99.8952 20.0569 100.656 20.204C101.417 20.3757 102.153 20.8172 102.693 21.5285C103.233 22.2399 103.478 23.0738 103.429 23.8342C103.38 24.5946 103.036 25.3059 102.447 25.7719Z"
          fill="white"
        />
        <path
          d="M93.4915 21.872C92.8535 22.3625 92.0437 22.5097 91.2339 22.338C90.424 22.1663 89.6633 21.7003 89.0989 20.9399C88.5345 20.1795 88.2891 19.321 88.3382 18.5116C88.3872 17.7021 88.7308 16.9663 89.3934 16.4757C90.0559 15.9852 90.8412 15.838 91.651 16.0097C92.4608 16.1814 93.2216 16.6474 93.786 17.4078C94.3504 18.1682 94.6204 19.0267 94.5467 19.8361C94.4977 20.6455 94.1541 21.3814 93.4915 21.872Z"
          fill="white"
        />
        <path
          d="M84.3615 18.855C83.6743 19.3701 82.84 19.5172 82.0056 19.3455C81.1713 19.1738 80.3615 18.6588 79.7725 17.8738C79.1836 17.0889 78.9136 16.1814 78.9627 15.3229C79.0118 14.4644 79.3799 13.6795 80.067 13.1644C80.7541 12.6493 81.5885 12.5022 82.4228 12.6739C83.2572 12.8455 84.067 13.3606 84.6559 14.1455C85.2449 14.9305 85.5148 15.838 85.4658 16.6965C85.4167 17.555 85.0486 18.3399 84.3615 18.8305V18.855Z"
          fill="white"
        />
        <path
          d="M75.0856 16.721C74.374 17.2606 73.4905 17.4078 72.6071 17.2361C71.7237 17.0644 70.8893 16.5248 70.2513 15.6908C69.6132 14.8569 69.3433 13.9002 69.3924 13.0172C69.4414 12.1342 69.8341 11.3002 70.5457 10.7606C71.2574 10.221 72.1408 10.0738 73.0243 10.2701C73.9077 10.4663 74.7421 10.9814 75.3801 11.8153C76.0181 12.6493 76.2881 13.6059 76.239 14.4889C76.1899 15.3719 75.7973 16.2059 75.0856 16.7455V16.721Z"
          fill="white"
        />
        <path
          d="M65.7858 15.4946C65.0496 16.0587 64.1171 16.2059 63.2092 16.0097C62.3012 15.8135 61.3932 15.2738 60.7552 14.3908C60.1171 13.5078 59.7981 12.5267 59.8717 11.5946C59.9208 10.6625 60.338 9.80403 61.0742 9.23988C61.8104 8.67573 62.7429 8.52856 63.6509 8.72479C64.5589 8.92102 65.4668 9.46064 66.1049 10.3437C66.7429 11.2267 67.0619 12.2078 66.9883 13.1399C66.9392 14.072 66.522 14.9304 65.7858 15.4946Z"
          fill="white"
        />
        <path
          d="M56.5341 15.1512C55.7734 15.7399 54.7918 15.9116 53.8348 15.6908C52.8777 15.4701 51.9452 14.9059 51.2581 14.0229C50.5709 13.1399 50.2765 12.0852 50.3256 11.104C50.3746 10.1229 50.8163 9.23987 51.5771 8.65119C52.3378 8.06251 53.3194 7.89082 54.2765 8.11158C55.2335 8.3078 56.166 8.89648 56.8286 9.7795C57.4912 10.6625 57.8102 11.7172 57.7611 12.6984C57.7121 13.6795 57.2703 14.5625 56.5096 15.1512H56.5341Z"
          fill="white"
        />
        <path
          d="M47.3804 15.6418C46.5705 16.255 45.5644 16.4267 44.5583 16.2059C43.5767 15.9852 42.5951 15.3965 41.8834 14.4644C41.1718 13.5323 40.8527 12.4286 40.9264 11.4229C41 10.4172 41.4172 9.48515 42.227 8.87195C43.0368 8.25874 44.0429 8.08704 45.0491 8.3078C46.0307 8.52855 47.0123 9.11724 47.7239 10.0493C48.4356 10.9814 48.7546 12.0852 48.681 13.0908C48.6319 14.0965 48.1902 15.0286 47.3804 15.6418Z"
          fill="white"
        />
        <path
          d="M38.3986 16.9172C37.5642 17.555 36.509 17.7267 35.4783 17.5059C34.4477 17.2852 33.4415 16.672 32.7053 15.6908C31.9692 14.7097 31.6256 13.5814 31.6992 12.5267C31.7728 11.472 32.2145 10.5154 33.0489 9.87762C33.8833 9.23988 34.9385 9.06819 35.9691 9.28894C36.9998 9.5097 38.006 10.1229 38.7422 11.104C39.4784 12.0852 39.8219 13.2135 39.7483 14.2682C39.6747 15.3229 39.233 16.2795 38.3986 16.9172Z"
          fill="white"
        />
        <path
          d="M29.6378 18.9531C28.7789 19.6153 27.6746 19.787 26.5949 19.5663C25.5151 19.3455 24.4844 18.6833 23.6992 17.6776C22.9139 16.6719 22.5949 15.4946 22.6685 14.3908C22.7421 13.287 23.2084 12.2814 24.0673 11.6436C24.9262 11.0059 26.0305 10.8097 27.1102 11.0304C28.19 11.2512 29.2206 11.9135 30.0059 12.9191C30.7912 13.9248 31.1102 15.1021 31.0611 16.2059C30.9875 17.3097 30.5213 18.3153 29.6378 18.9531Z"
          fill="white"
        />
      </motion.svg>
      <motion.svg
        width="253"
        height="128"
        viewBox="0 0 253 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial={{ x: '100%', opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <path
          d="M42.16 109.268C41.8655 108.925 41.4238 108.655 40.8594 108.483C40.2949 108.312 39.6078 108.238 38.8716 108.312L34.4545 108.753L30.0373 109.195L25.6201 109.636L21.2029 110.078C20.9821 110.078 20.7367 110.102 20.5158 110.078C20.2949 110.078 20.0495 110.053 19.8532 110.004C19.6324 109.98 19.4115 109.906 19.2152 109.857C19.0189 109.808 18.8226 109.71 18.6508 109.636L15.2643 107.919L11.8778 106.202L8.49127 104.485L5.10476 102.768C4.54034 102.474 3.87777 102.327 3.23973 102.327C2.6017 102.327 1.96366 102.425 1.44832 102.596C0.908443 102.793 0.466726 103.087 0.221327 103.43C-0.0240719 103.774 -0.0976915 104.191 0.0740876 104.632L2.42992 110.029L4.78574 115.425L7.14157 120.821L9.4974 126.217C9.64464 126.561 9.93912 126.855 10.3072 127.076C10.6999 127.296 11.1661 127.444 11.6569 127.542C12.1722 127.615 12.7121 127.64 13.2275 127.542C13.7428 127.468 14.2581 127.321 14.6753 127.076L21.3992 123.176L28.1232 119.276L34.8471 115.376L41.571 111.476C42.1109 111.157 42.4054 110.789 42.479 110.421C42.5526 110.053 42.4299 109.661 42.16 109.342V109.268Z"
          fill="white"
        />
        <path
          d="M252.221 70.5625C252.221 70.5625 252.344 70.5625 252.393 70.5625C252.442 70.5625 252.491 70.5135 252.516 70.489C252.54 70.4644 252.54 70.4154 252.516 70.3908C252.516 70.3663 252.442 70.3173 252.393 70.3173C252.344 70.3173 252.27 70.3173 252.221 70.3173C252.172 70.3173 252.123 70.3663 252.098 70.3908C252.074 70.4154 252.074 70.4644 252.098 70.489C252.098 70.5135 252.172 70.538 252.221 70.5625Z"
          fill="white"
        />
        <path
          d="M249.841 62.1248C249.841 62.1248 250.086 62.1493 250.209 62.1248C250.332 62.1248 250.43 62.0512 250.479 61.9776C250.528 61.904 250.528 61.8305 250.479 61.7569C250.43 61.6833 250.356 61.6342 250.233 61.6097C250.111 61.5852 249.988 61.5852 249.865 61.6097C249.743 61.6097 249.644 61.6833 249.595 61.7569C249.546 61.8305 249.546 61.904 249.595 61.9776C249.644 62.0512 249.718 62.1003 249.841 62.1248Z"
          fill="white"
        />
        <path
          d="M246.38 53.8342C246.552 53.8833 246.748 53.8833 246.92 53.8342C247.092 53.8097 247.239 53.7116 247.313 53.6135C247.386 53.5153 247.386 53.3927 247.313 53.2946C247.239 53.1965 247.117 53.0984 246.945 53.0739C246.773 53.0493 246.577 53.0248 246.405 53.0739C246.233 53.1229 246.086 53.1965 246.012 53.2946C245.939 53.3927 245.939 53.5153 246.012 53.6135C246.086 53.7116 246.209 53.8097 246.38 53.8342Z"
          fill="white"
        />
        <path
          d="M241.866 45.7154C242.111 45.7889 242.356 45.7889 242.577 45.7154C242.798 45.6663 242.994 45.5682 243.093 45.421C243.191 45.2738 243.191 45.1267 243.117 45.004C243.043 44.8814 242.872 44.7587 242.626 44.6852C242.381 44.6116 242.135 44.6116 241.915 44.6852C241.694 44.7342 241.497 44.8323 241.399 44.9795C241.301 45.1267 241.301 45.2738 241.375 45.3965C241.448 45.5191 241.62 45.6418 241.866 45.7154Z"
          fill="white"
        />
        <path
          d="M236.318 37.8172C236.612 37.8908 236.931 37.8908 237.226 37.8172C237.496 37.7436 237.741 37.621 237.888 37.4493C238.036 37.2776 238.036 37.0814 237.913 36.9097C237.79 36.738 237.594 36.5908 237.299 36.5172C237.005 36.4436 236.686 36.4436 236.392 36.5172C236.122 36.5908 235.876 36.7135 235.729 36.8852C235.582 37.0569 235.582 37.2531 235.704 37.4248C235.803 37.5965 236.023 37.7436 236.318 37.8172Z"
          fill="white"
        />
        <path
          d="M229.668 30.2135C230.012 30.3116 230.404 30.3116 230.748 30.2135C231.092 30.1399 231.386 29.9927 231.533 29.772C231.681 29.5512 231.705 29.3305 231.582 29.1342C231.46 28.938 231.214 28.7663 230.846 28.6682C230.478 28.5701 230.11 28.5701 229.766 28.6682C229.423 28.7663 229.128 28.8889 228.981 29.1097C228.834 29.3305 228.809 29.5512 228.932 29.7474C229.055 29.9437 229.3 30.1154 229.668 30.2135Z"
          fill="white"
        />
        <path
          d="M221.815 23.0022C222.232 23.1248 222.674 23.1248 223.066 23.0267C223.459 22.9286 223.803 22.7569 223.974 22.5116C224.146 22.2663 224.171 21.9965 224.024 21.7757C223.876 21.555 223.582 21.3342 223.165 21.2361C222.747 21.138 222.306 21.1135 221.913 21.2116C221.52 21.3097 221.177 21.4814 221.005 21.7267C220.833 21.972 220.809 22.2418 220.956 22.4625C221.103 22.6833 221.398 22.9041 221.815 23.0022Z"
          fill="white"
        />
        <path
          d="M212.613 16.3795C213.079 16.5021 213.594 16.5021 214.036 16.404C214.478 16.3059 214.87 16.1097 215.091 15.8154C215.312 15.521 215.312 15.2267 215.14 14.9569C214.969 14.687 214.625 14.4663 214.159 14.3437C213.692 14.221 213.177 14.221 212.735 14.3191C212.294 14.4172 211.901 14.6135 211.68 14.9078C211.459 15.2022 211.459 15.4965 211.631 15.7663C211.803 16.0361 212.146 16.2569 212.613 16.3795Z"
          fill="white"
        />
        <path
          d="M201.84 10.5908C202.38 10.738 202.944 10.738 203.459 10.6154C203.975 10.4927 204.392 10.272 204.637 9.95309C204.883 9.63422 204.883 9.29082 204.686 8.99648C204.49 8.70214 204.122 8.43233 203.582 8.30969C203.042 8.18704 202.478 8.16252 201.962 8.28516C201.447 8.4078 201.03 8.62856 200.784 8.94743C200.539 9.26629 200.539 9.60969 200.735 9.90403C200.932 10.1984 201.3 10.4682 201.84 10.6154V10.5908Z"
          fill="white"
        />
        <path
          d="M189.424 6.07763C190.013 6.2248 190.651 6.24933 191.215 6.12669C191.78 6.00404 192.27 5.75876 192.516 5.41536C192.761 5.07197 192.786 4.67951 192.59 4.36064C192.369 4.01725 191.951 3.74744 191.363 3.57574C190.774 3.40404 190.136 3.40404 189.571 3.52668C189.007 3.64932 188.516 3.89461 188.246 4.23801C187.976 4.5814 187.976 4.97386 188.172 5.29272C188.369 5.63612 188.81 5.90593 189.399 6.07763H189.424Z"
          fill="white"
        />
        <path
          d="M175.46 3.47763C176.098 3.64933 176.81 3.64932 177.423 3.52668C178.037 3.37951 178.577 3.1097 178.871 2.74177C179.166 2.37385 179.166 1.93234 178.945 1.56441C178.724 1.19649 178.258 0.877626 177.595 0.705928C176.933 0.53423 176.246 0.534229 175.632 0.65687C175.019 0.80404 174.479 1.07385 174.184 1.44178C173.89 1.8097 173.89 2.25121 174.111 2.61913C174.331 2.98706 174.798 3.30593 175.46 3.47763Z"
          fill="white"
        />
        <path
          d="M160.81 3.18327C161.522 3.3795 162.283 3.3795 162.97 3.23233C163.657 3.08516 164.221 2.79082 164.54 2.37384C164.859 1.95686 164.859 1.49082 164.614 1.09837C164.369 0.705916 163.853 0.362517 163.142 0.166291C162.43 -0.0299355 161.669 -0.0299367 160.982 0.117233C160.295 0.264403 159.73 0.558748 159.411 0.975729C159.092 1.39271 159.092 1.85875 159.338 2.2512C159.583 2.64366 160.099 2.98704 160.81 3.18327Z"
          fill="white"
        />
        <path
          d="M146.356 4.9493C147.117 5.14553 147.952 5.17006 148.688 4.99836C149.424 4.82666 150.037 4.50779 150.406 4.06628C150.774 3.62477 150.749 3.10968 150.479 2.69269C150.209 2.25119 149.645 1.88326 148.884 1.68703C148.123 1.49081 147.289 1.46629 146.553 1.63799C145.817 1.80968 145.203 2.12855 144.835 2.57006C144.467 3.01156 144.491 3.52666 144.761 3.94364C145.031 4.36062 145.596 4.75308 146.356 4.9493Z"
          fill="white"
        />
        <path
          d="M132.661 8.26062C133.496 8.48138 134.379 8.48138 135.165 8.30968C135.95 8.13798 136.637 7.79459 137.005 7.30402C137.373 6.81345 137.398 6.27383 137.103 5.80779C136.809 5.34175 136.22 4.9493 135.385 4.72855C134.551 4.50779 133.668 4.50779 132.882 4.67949C132.097 4.85119 131.41 5.19459 131.042 5.68515C130.674 6.17572 130.649 6.71534 130.944 7.18138C131.238 7.64742 131.827 8.03987 132.661 8.26062Z"
          fill="white"
        />
        <path
          d="M119.877 12.7003C120.76 12.9455 121.717 12.9455 122.576 12.7493C123.41 12.5531 124.147 12.1852 124.539 11.6701C124.932 11.155 124.956 10.5663 124.637 10.0757C124.318 9.58516 123.68 9.14365 122.797 8.9229C121.913 8.70214 120.956 8.67761 120.098 8.87384C119.239 9.07006 118.527 9.438 118.134 9.95309C117.742 10.4682 117.717 11.0323 118.036 11.5474C118.355 12.038 118.993 12.4795 119.877 12.7003Z"
          fill="white"
        />
        <path
          d="M108.024 17.9493C108.957 18.1946 109.987 18.2191 110.895 17.9984C111.803 17.8021 112.564 17.4097 113.006 16.8455C113.448 16.2814 113.448 15.6927 113.104 15.1531C112.76 14.6135 112.098 14.1719 111.141 13.9021C110.184 13.6323 109.178 13.6323 108.27 13.8285C107.362 14.0248 106.601 14.4172 106.159 14.9814C105.717 15.5455 105.717 16.1342 106.061 16.6738C106.405 17.2135 107.067 17.655 108.024 17.9248V17.9493Z"
          fill="white"
        />
        <path
          d="M97.0059 23.8361C98.0121 24.1059 99.0918 24.1059 100.049 23.9097C101.006 23.6889 101.816 23.272 102.282 22.6833C102.748 22.0946 102.748 21.4569 102.405 20.8927C102.037 20.3286 101.325 19.838 100.319 19.5682C99.3127 19.2984 98.2329 19.2984 97.2759 19.4946C96.3188 19.7154 95.509 20.1323 95.0427 20.721C94.5765 21.3097 94.5765 21.9474 94.9446 22.5116C95.3127 23.0757 96.0243 23.5663 97.0305 23.8361H97.0059Z"
          fill="white"
        />
        <path
          d="M86.7965 30.2135C87.8518 30.5078 89.0051 30.5078 90.0358 30.2871C91.0665 30.0663 91.9254 29.6248 92.3916 28.9871C92.8579 28.3493 92.8824 27.6871 92.5143 27.0739C92.1462 26.4852 91.3855 25.9701 90.3057 25.6757C89.226 25.3814 88.0972 25.3814 87.0665 25.6021C86.0603 25.8229 85.1769 26.2889 84.7107 26.9022C84.2444 27.5154 84.2199 28.2022 84.588 28.8154C84.9561 29.404 85.7413 29.9191 86.7965 30.2135Z"
          fill="white"
        />
        <path
          d="M77.3249 36.9833C78.4537 37.2776 79.6562 37.3022 80.7359 37.0569C81.8157 36.8116 82.7236 36.3455 83.239 35.6833C83.7543 35.021 83.7543 34.3097 83.3617 33.672C82.9691 33.0342 82.1592 32.4946 81.0304 32.2003C79.9016 31.9059 78.6991 31.8814 77.6194 32.1267C76.5396 32.372 75.6316 32.838 75.1163 33.5003C74.601 34.1625 74.601 34.8739 74.9936 35.5116C75.3862 36.1493 76.196 36.689 77.3249 36.9833Z"
          fill="white"
        />
        <path
          d="M68.5636 44.072C69.7415 44.3908 71.0175 44.3908 72.1464 44.1455C73.2752 43.9003 74.2323 43.3852 74.7721 42.6984C75.312 42.0116 75.312 41.2512 74.8948 40.5889C74.4777 39.9267 73.6188 39.3625 72.4409 39.0437C71.2629 38.7248 69.9869 38.7248 68.858 38.9701C67.7292 39.2153 66.7721 39.7304 66.2323 40.4172C65.6924 41.104 65.6924 41.8644 66.1096 42.5267C66.5268 43.1889 67.3856 43.7531 68.5636 44.072Z"
          fill="white"
        />
        <path
          d="M60.4654 51.4304C61.6924 51.7738 63.0421 51.7739 64.22 51.504C65.3979 51.2342 66.4041 50.7191 66.9685 49.9833C67.5329 49.2474 67.5329 48.4625 67.0912 47.7512C66.6495 47.0399 65.766 46.4512 64.5145 46.1323C63.263 45.8135 61.9378 45.7889 60.7353 46.0588C59.5574 46.3286 58.5513 46.8437 57.9869 47.5795C57.4225 48.3153 57.3979 49.1003 57.8396 49.8116C58.2814 50.4984 59.1648 51.1116 60.4163 51.455L60.4654 51.4304Z"
          fill="white"
        />
        <path
          d="M52.9811 59.0342C54.2817 59.3776 55.6805 59.4021 56.9321 59.1078C58.1836 58.8134 59.2388 58.2738 59.8278 57.5134C60.4167 56.7531 60.4413 55.9191 59.975 55.1833C59.5087 54.4474 58.5762 53.8342 57.2756 53.4663C55.975 53.0983 54.5762 53.0983 53.3247 53.3927C52.0977 53.6625 51.0179 54.2267 50.429 54.987C49.84 55.7474 49.8155 56.5814 50.2817 57.3172C50.748 58.0531 51.6805 58.6663 52.9811 59.0342Z"
          fill="white"
        />
        <path
          d="M46.085 66.8097C47.4347 67.1776 48.9071 67.1776 50.2077 66.9078C51.5083 66.6135 52.6126 66.0493 53.2261 65.2399C53.8396 64.4305 53.8641 63.5719 53.3734 62.8116C52.8826 62.0512 51.9255 61.3889 50.5513 61.021C49.177 60.6531 47.7292 60.6531 46.4286 60.9229C45.128 61.2172 44.0237 61.7814 43.4102 62.5908C42.7967 63.4003 42.7721 64.2587 43.2629 65.0191C43.7537 65.7795 44.7108 66.4418 46.085 66.8097Z"
          fill="white"
        />
        <path
          d="M39.7795 74.7814C41.2028 75.1738 42.7242 75.1739 44.0739 74.8795C45.4236 74.5852 46.577 73.972 47.2396 73.138C47.8776 72.304 47.9022 71.3965 47.3868 70.5871C46.896 69.7776 45.8653 69.1154 44.442 68.7229C43.0187 68.3304 41.4973 68.3304 40.1476 68.6248C38.7979 68.9191 37.6445 69.5323 36.9819 70.3663C36.3193 71.2003 36.3193 72.1078 36.8347 72.9172C37.35 73.7267 38.3561 74.3889 39.7795 74.7814Z"
          fill="white"
        />
        <path
          d="M33.9869 82.8757C35.4593 83.2682 37.0544 83.2927 38.4777 82.9738C39.8765 82.655 41.079 82.0418 41.7661 81.1588C42.4532 80.2757 42.4532 79.3437 41.9379 78.5097C41.4225 77.6757 40.3428 76.9644 38.8704 76.572C37.398 76.1795 35.8029 76.1795 34.3796 76.4738C32.9563 76.7682 31.7783 77.4059 31.0912 78.289C30.4287 79.1474 30.4041 80.1041 30.9195 80.938C31.4348 81.772 32.5145 82.4833 33.9869 82.8757Z"
          fill="white"
        />
        <path
          d="M28.7362 91.0927C30.2577 91.5097 31.9264 91.5097 33.3988 91.1908C34.8712 90.8719 36.1227 90.2097 36.8098 89.3021C37.4969 88.3946 37.5215 87.4134 36.9816 86.555C36.4417 85.6965 35.3374 84.9606 33.8159 84.5436C32.2945 84.1267 30.6258 84.1267 29.1534 84.4455C27.681 84.7644 26.4294 85.4267 25.7423 86.3342C25.0552 87.2418 25.0307 88.2229 25.5705 89.0814C26.1104 89.9399 27.2147 90.6757 28.7607 91.0927H28.7362Z"
          fill="white"
        />
        <path
          d="M23.999 99.4323C25.594 99.8738 27.3118 99.8738 28.8333 99.5304C30.3548 99.187 31.6554 98.5248 32.3916 97.5682C33.1278 96.6116 33.1278 95.6059 32.5634 94.6984C31.999 93.7908 30.8456 93.0304 29.275 92.6135C27.7045 92.1965 25.9376 92.1719 24.4407 92.5153C22.9192 92.8587 21.6186 93.521 20.8824 94.4776C20.1462 95.4342 20.1462 96.4399 20.7106 97.3474C21.275 98.255 22.4284 99.0153 23.999 99.4323Z"
          fill="white"
        />
        <path
          d="M19.7544 107.846C21.3985 108.287 23.19 108.287 24.785 107.944C26.3556 107.6 27.7053 106.889 28.466 105.908C29.2268 104.927 29.2268 103.872 28.6378 102.94C28.0488 102.008 26.8709 101.223 25.2022 100.757C23.5335 100.291 21.7666 100.315 20.1715 100.659C18.601 101.002 17.2513 101.713 16.4906 102.695C15.7298 103.676 15.7298 104.73 16.3188 105.663C16.9077 106.595 18.0857 107.379 19.7544 107.846Z"
          fill="white"
        />
      </motion.svg>

      <motion.svg
        width="457"
        height="208"
        viewBox="0 0 483 239"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial={{ x: '-100%', opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <path
          d="M429.209 85.5531C429.503 84.9153 430.043 84.2286 430.73 83.5908C431.418 82.9286 432.301 82.3153 433.283 81.8493L439.099 79.0776L444.914 76.3059L450.73 73.5342L456.546 70.7625C456.841 70.6153 457.16 70.4436 457.43 70.2719C457.724 70.1002 458.019 69.904 458.289 69.6833C458.559 69.4625 458.829 69.2418 459.074 69.021C459.319 68.8002 459.565 68.555 459.761 68.3097L463.835 63.5757L467.884 58.8418L471.933 54.1078L476.006 49.3738C476.694 48.5644 477.503 47.9021 478.338 47.387C479.172 46.8719 480.006 46.5285 480.743 46.3814C481.479 46.2342 482.092 46.2587 482.509 46.504C482.902 46.7493 483.098 47.2153 482.927 47.9021L480.914 56.4625L478.902 65.0229L476.89 73.5833L474.878 82.1437C474.755 82.6833 474.436 83.2719 473.97 83.8361C473.528 84.4002 472.939 84.9644 472.301 85.4304C471.663 85.921 470.951 86.338 470.264 86.6323C469.577 86.9267 468.89 87.1229 468.301 87.1229L458.804 87.2946L449.307 87.4663L439.81 87.638L430.313 87.8097C429.552 87.8097 429.086 87.5889 428.914 87.172C428.743 86.755 428.816 86.1908 429.135 85.5531H429.209Z"
          fill="white"
        />
        <path
          d="M1.23315 238.487C1.23315 238.487 1.01229 238.634 0.889596 238.683C0.766896 238.732 0.668735 238.732 0.619655 238.683C0.570576 238.634 0.546031 238.585 0.59511 238.512C0.61965 238.438 0.69328 238.34 0.815979 238.266C0.938678 238.193 1.03684 238.119 1.15954 238.07C1.28224 238.021 1.35586 238.021 1.42948 238.07C1.5031 238.119 1.50309 238.168 1.47855 238.242C1.45401 238.315 1.3804 238.413 1.2577 238.487H1.23315Z"
          fill="white"
        />
        <path
          d="M3.75999 222.74C3.53913 222.912 3.29374 223.034 3.07288 223.108C2.85202 223.181 2.6557 223.206 2.533 223.157C2.4103 223.108 2.38576 222.985 2.45938 222.838C2.50846 222.691 2.68024 222.519 2.87655 222.347C3.07287 222.176 3.34283 222.053 3.56369 221.979C3.78455 221.906 3.98085 221.881 4.10355 221.955C4.22625 222.029 4.2508 222.127 4.17718 222.274C4.1281 222.421 3.95631 222.593 3.75999 222.764V222.74Z"
          fill="white"
        />
        <path
          d="M8.37364 206.11C8.05462 206.355 7.68652 206.551 7.3675 206.674C7.04848 206.797 6.754 206.821 6.55768 206.723C6.36136 206.625 6.33683 206.453 6.43499 206.232C6.53315 206.012 6.754 205.742 7.07301 205.496C7.39203 205.251 7.76014 205.055 8.07916 204.932C8.39818 204.81 8.69266 204.785 8.88898 204.883C9.0853 204.981 9.0853 205.153 8.98714 205.374C8.88898 205.595 8.66812 205.864 8.3491 206.11H8.37364Z"
          fill="white"
        />
        <path
          d="M15.0241 188.695C14.5824 189.038 14.1162 189.283 13.6744 189.43C13.2327 189.578 12.8401 189.602 12.6192 189.504C12.3984 189.406 12.3493 189.161 12.472 188.866C12.5947 188.572 12.8892 188.229 13.3309 187.885C13.7726 187.542 14.2634 187.296 14.6806 187.149C15.1223 187.002 15.5149 186.978 15.7358 187.076C15.9567 187.174 16.0057 187.419 15.883 187.713C15.7603 188.008 15.4659 188.351 15.0241 188.695Z"
          fill="white"
        />
        <path
          d="M23.7112 170.593C23.1713 171.01 22.5578 171.329 22.0179 171.525C21.478 171.696 20.9872 171.746 20.6927 171.623C20.3983 171.5 20.3492 171.181 20.4964 170.813C20.6437 170.446 21.0363 170.004 21.5762 169.587C22.1161 169.17 22.7296 168.851 23.2694 168.655C23.8093 168.483 24.3001 168.434 24.5946 168.581C24.8891 168.729 24.9381 169.023 24.7909 169.391C24.6191 169.759 24.251 170.2 23.7112 170.617V170.593Z"
          fill="white"
        />
        <path
          d="M34.5575 151.853C33.9195 152.368 33.1833 152.736 32.5207 152.957C31.8581 153.178 31.2692 153.227 30.9256 153.055C30.582 152.883 30.5084 152.515 30.7047 152.074C30.9011 151.632 31.3428 151.117 31.9808 150.602C32.6188 150.087 33.355 149.695 34.0176 149.474C34.6802 149.253 35.2692 149.204 35.6127 149.376C35.9563 149.547 36.0299 149.891 35.8336 150.332C35.6372 150.774 35.1955 151.313 34.5575 151.804V151.853Z"
          fill="white"
        />
        <path
          d="M47.7855 132.525C47.0248 133.113 46.1659 133.579 45.4052 133.825C44.6444 134.095 43.9573 134.144 43.5401 133.947C43.123 133.751 43.0493 133.334 43.2702 132.819C43.4911 132.304 44.0064 131.691 44.7671 131.102C45.5279 130.513 46.3868 130.047 47.1475 129.802C47.9082 129.532 48.5954 129.483 49.0125 129.679C49.4297 129.876 49.5033 130.293 49.2825 130.808C49.0616 131.323 48.5463 131.936 47.7855 132.525Z"
          fill="white"
        />
        <path
          d="M63.7354 112.73C62.8765 113.393 61.8949 113.932 61.0115 114.227C60.1281 114.521 59.3428 114.595 58.8765 114.374C58.4103 114.153 58.3121 113.663 58.5821 113.074C58.8275 112.485 59.4164 111.774 60.2998 111.112C61.1833 110.449 62.1403 109.91 63.0238 109.615C63.9072 109.321 64.6925 109.247 65.1588 109.468C65.625 109.689 65.7232 110.18 65.4532 110.768C65.2078 111.357 64.6189 112.068 63.7354 112.73Z"
          fill="white"
        />
        <path
          d="M82.8277 92.7399C81.8461 93.5003 80.7663 94.089 79.7602 94.4078C78.7541 94.7267 77.8951 94.8248 77.3553 94.555C76.8154 94.2852 76.7418 93.7701 77.0117 93.1078C77.3062 92.4456 77.9688 91.6607 78.9504 90.9003C79.932 90.1399 81.0117 89.5512 82.0179 89.2324C82.9994 88.9135 83.8829 88.8154 84.4227 89.0852C84.9626 89.355 85.0362 89.8701 84.7663 90.5323C84.4964 91.1946 83.8093 91.9795 82.8522 92.7399H82.8277Z"
          fill="white"
        />
        <path
          d="M105.405 73.0927C104.325 73.9267 103.098 74.5889 101.994 74.9569C100.89 75.3248 99.9327 75.4229 99.3192 75.1286C98.7057 74.8342 98.6321 74.2455 98.9511 73.5097C99.2702 72.7738 100.006 71.8908 101.086 71.0569C102.166 70.2229 103.393 69.5606 104.473 69.1927C105.577 68.8248 106.558 68.7267 107.147 69.021C107.736 69.3153 107.835 69.904 107.516 70.6399C107.197 71.3757 106.46 72.2587 105.381 73.0927H105.405Z"
          fill="white"
        />
        <path
          d="M131.294 54.9663C130.091 55.8984 128.766 56.6097 127.563 57.0267C126.361 57.4191 125.281 57.5172 124.619 57.2229C123.956 56.9286 123.858 56.2663 124.202 55.4569C124.545 54.6474 125.355 53.6908 126.557 52.7588C127.76 51.8267 129.085 51.1154 130.287 50.6984C131.49 50.2814 132.57 50.2078 133.232 50.5022C133.895 50.7965 133.993 51.4588 133.649 52.2682C133.306 53.0776 132.496 54.0342 131.294 54.9663Z"
          fill="white"
        />
        <path
          d="M159.123 39.7587C157.823 40.7644 156.35 41.5493 155.05 41.9908C153.724 42.4323 152.571 42.5304 151.859 42.2116C151.148 41.8927 151.025 41.1569 151.418 40.2738C151.81 39.3908 152.694 38.3361 153.994 37.3304C155.295 36.3248 156.767 35.5399 158.068 35.0984C159.393 34.6569 160.547 34.5587 161.258 34.8776C161.97 35.1965 162.093 35.9323 161.7 36.8154C161.307 37.6984 160.424 38.7531 159.123 39.7587Z"
          fill="white"
        />
        <path
          d="M186.976 28.0587C185.577 29.1625 183.982 29.9965 182.559 30.487C181.135 30.9776 179.859 31.0757 179.099 30.7078C178.338 30.3399 178.191 29.555 178.608 28.5984C179.025 27.6418 179.982 26.4889 181.381 25.4097C182.78 24.3304 184.375 23.4719 185.798 22.9814C187.221 22.4908 188.497 22.3927 189.258 22.7606C190.019 23.1286 190.166 23.9135 189.749 24.8701C189.332 25.8267 188.375 26.9795 186.976 28.0587Z"
          fill="white"
        />
        <path
          d="M213.773 19.5474C212.251 20.7248 210.558 21.6323 209.012 22.1474C207.466 22.6625 206.116 22.7852 205.282 22.3927C204.448 22.0002 204.325 21.1663 204.767 20.1361C205.208 19.1059 206.239 17.8795 207.761 16.7021C209.282 15.5248 210.975 14.6172 212.521 14.1021C214.067 13.587 215.417 13.4644 216.251 13.8569C217.086 14.2493 217.208 15.0833 216.767 16.1135C216.325 17.1436 215.294 18.3701 213.773 19.5474Z"
          fill="white"
        />
        <path
          d="M239.123 13.7097C237.504 14.9852 235.663 15.9418 234.019 16.5059C232.375 17.0455 230.903 17.1927 230.019 16.7757C229.136 16.3587 228.988 15.4512 229.455 14.3474C229.946 13.2436 231.05 11.9191 232.669 10.6682C234.289 9.41723 236.13 8.43609 237.774 7.87194C239.418 7.30779 240.89 7.18515 241.774 7.60213C242.657 8.01911 242.804 8.92666 242.338 10.0304C241.847 11.1342 240.743 12.4587 239.123 13.7097Z"
          fill="white"
        />
        <path
          d="M262.927 10.1286C261.185 11.4776 259.246 12.5078 257.48 13.0965C255.713 13.6852 254.167 13.8323 253.21 13.3663C252.253 12.9003 252.105 11.9682 252.621 10.7663C253.136 9.58893 254.314 8.19082 256.056 6.84176C257.799 5.4927 259.737 4.43799 261.504 3.84931C263.271 3.26063 264.817 3.11346 265.774 3.5795C266.731 4.04553 266.878 4.97761 266.363 6.15497C265.848 7.33233 264.67 8.75497 262.927 10.104V10.1286Z"
          fill="white"
        />
        <path
          d="M285.233 8.38705C283.393 9.83422 281.331 10.938 279.442 11.5512C277.577 12.1889 275.908 12.3361 274.902 11.8455C273.896 11.355 273.724 10.3493 274.264 9.09837C274.804 7.84743 276.055 6.3512 277.896 4.90403C279.736 3.45686 281.797 2.37761 283.687 1.73988C285.552 1.10214 287.221 0.954973 288.227 1.44554C289.233 1.9361 289.405 2.94177 288.865 4.19271C288.325 5.44365 287.073 6.93988 285.233 8.38705Z"
          fill="white"
        />
        <path
          d="M306.141 8.23988C304.202 9.76063 301.994 10.938 300.03 11.6003C298.043 12.2625 296.3 12.4097 295.221 11.9191C294.165 11.404 293.969 10.3493 294.558 9.00026C295.122 7.67573 296.472 6.08139 298.411 4.56063C300.349 3.03988 302.558 1.86252 304.521 1.20026C306.509 0.537992 308.251 0.390822 309.331 0.881388C310.411 1.39648 310.583 2.4512 309.994 3.80026C309.429 5.12478 308.079 6.71912 306.141 8.23988Z"
          fill="white"
        />
        <path
          d="M325.724 9.49082C323.663 11.0852 321.356 12.3361 319.27 13.0229C317.184 13.7097 315.319 13.8814 314.191 13.3418C313.062 12.8021 312.865 11.6738 313.479 10.2757C314.092 8.87762 315.491 7.20969 317.553 5.59082C319.614 3.97196 321.921 2.74554 324.007 2.05875C326.092 1.34743 327.957 1.20026 329.086 1.73988C330.215 2.2795 330.411 3.40781 329.798 4.80592C329.184 6.20403 327.786 7.87196 325.724 9.49082Z"
          fill="white"
        />
        <path
          d="M344.055 11.9191C341.895 13.6116 339.441 14.9116 337.257 15.6474C335.049 16.3833 333.11 16.555 331.932 15.9908C330.754 15.4267 330.558 14.2248 331.196 12.7531C331.834 11.2814 333.306 9.51534 335.466 7.82288C337.625 6.13043 340.079 4.83043 342.263 4.09458C344.448 3.35873 346.411 3.18703 347.589 3.75118C348.767 4.31534 348.963 5.51722 348.325 6.98892C347.687 8.46062 346.214 10.2267 344.055 11.9191Z"
          fill="white"
        />
        <path
          d="M361.185 15.4757C358.903 17.2418 356.35 18.6153 354.044 19.3757C351.737 20.1361 349.676 20.3323 348.449 19.7436C347.222 19.155 347.001 17.904 347.663 16.3587C348.326 14.8135 349.896 12.9738 352.154 11.1833C354.436 9.41723 356.988 8.04365 359.295 7.25874C361.602 6.47384 363.639 6.30214 364.89 6.89082C366.142 7.4795 366.338 8.73044 365.676 10.2757C365.013 11.821 363.442 13.6606 361.185 15.4512V15.4757Z"
          fill="white"
        />
        <path
          d="M377.209 19.9889C374.829 21.8531 372.154 23.2757 369.725 24.0851C367.295 24.8946 365.16 25.0908 363.86 24.4776C362.559 23.8644 362.338 22.5399 363.05 20.921C363.762 19.3021 365.381 17.3644 367.762 15.5002C370.142 13.6361 372.817 12.2134 375.246 11.404C377.651 10.5946 379.811 10.3984 381.111 11.0116C382.412 11.6248 382.633 12.9493 381.921 14.5682C381.209 16.187 379.59 18.1248 377.209 19.9889Z"
          fill="white"
        />
        <path
          d="M392.129 25.4342C389.65 27.3719 386.828 28.8682 384.301 29.7267C381.773 30.5852 379.54 30.7814 378.166 30.1191C376.791 29.4569 376.571 28.1078 377.307 26.3908C378.043 24.6984 379.736 22.6625 382.239 20.7248C384.718 18.787 387.54 17.2908 390.067 16.4323C392.595 15.5984 394.828 15.3776 396.202 16.0399C397.577 16.7021 397.798 18.0512 397.061 19.7682C396.325 21.4606 394.632 23.4965 392.129 25.4342Z"
          fill="white"
        />
        <path
          d="M406.018 31.6889C403.417 33.7248 400.497 35.2701 397.846 36.1531C395.221 37.0361 392.865 37.2569 391.442 36.5701C390.018 35.8833 389.773 34.4606 390.558 32.6946C391.319 30.9286 393.11 28.8191 395.687 26.7833C398.264 24.7474 401.208 23.2022 403.834 22.3191C406.46 21.4361 408.816 21.2154 410.239 21.9022C411.662 22.5889 411.908 24.0116 411.123 25.7776C410.362 27.5437 408.57 29.6531 405.994 31.6889H406.018Z"
          fill="white"
        />
        <path
          d="M418.901 38.7286C416.202 40.838 413.159 42.4569 410.411 43.3889C407.662 44.321 405.233 44.5418 403.736 43.8304C402.239 43.1191 401.993 41.6229 402.803 39.7833C403.613 37.9436 405.454 35.7361 408.153 33.6267C410.852 31.5172 413.92 29.8984 416.644 28.9663C419.368 28.0342 421.822 27.8135 423.319 28.5248C424.791 29.2361 425.036 30.7323 424.251 32.5719C423.441 34.4116 421.601 36.6191 418.901 38.7286Z"
          fill="white"
        />
        <path
          d="M430.829 46.504C428.007 48.687 424.841 50.3795 421.994 51.3361C419.148 52.2927 416.596 52.5135 415.05 51.8021C413.504 51.0908 413.258 49.521 414.093 47.6078C414.927 45.6946 416.841 43.4135 419.663 41.2059C422.485 38.9984 425.651 37.3304 428.498 36.3738C431.344 35.4172 433.896 35.1965 435.442 35.9323C436.988 36.6682 437.234 38.2135 436.399 40.1267C435.565 42.0399 433.651 44.321 430.829 46.5286V46.504Z"
          fill="white"
        />
        <path
          d="M441.797 54.9663C438.877 57.2474 435.589 58.9889 432.619 59.9946C429.65 61.0003 427.024 61.221 425.429 60.4606C423.834 59.7003 423.564 58.0814 424.423 56.0946C425.282 54.1078 427.27 51.7286 430.215 49.4474C433.159 47.1663 436.423 45.4003 439.393 44.4191C442.362 43.4135 444.988 43.1927 446.583 43.9531C448.178 44.7135 448.448 46.3323 447.589 48.3191C446.73 50.3059 444.742 52.6852 441.797 54.9663Z"
          fill="white"
        />
        <path
          d="M451.834 64.0418C448.815 66.3965 445.38 68.2361 442.312 69.2663C439.245 70.2965 436.496 70.5418 434.852 69.7569C433.208 68.972 432.913 67.304 433.797 65.2191C434.705 63.1588 436.766 60.7059 439.785 58.3267C442.803 55.9474 446.238 54.1323 449.306 53.1022C452.373 52.072 455.122 51.8267 456.766 52.6116C458.41 53.3965 458.705 55.0644 457.797 57.1493C456.889 59.2097 454.827 61.6625 451.809 64.0418H451.834Z"
          fill="white"
        />
      </motion.svg>

      <motion.svg
        width="160"
        height="200"
        viewBox="0 0 160 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial={{ x: '100%', opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <g transform="rotate(20)">
          <path
            d="M20.0421 1.31724C20.4102 1.56252 20.7292 1.88139 20.9255 2.27384C21.1218 2.6663 21.22 3.13233 21.1464 3.6229L20.7292 6.49271L20.312 9.36252L19.8948 12.2323L19.4777 15.1021C19.4777 15.2493 19.4531 15.3965 19.4777 15.5437C19.4777 15.6908 19.5267 15.838 19.5513 16.0097C19.6004 16.1569 19.6494 16.304 19.7231 16.4512C19.7967 16.5984 19.8703 16.721 19.993 16.8682L21.9317 19.3455L23.8703 21.8229L25.809 24.3003L27.7476 26.7776C28.0666 27.1946 28.2384 27.6606 28.2629 28.0776C28.2875 28.4946 28.1893 28.9116 27.993 29.2304C27.7967 29.5493 27.4777 29.8191 27.1096 29.9418C26.7415 30.0644 26.2998 30.0644 25.809 29.8682L19.9194 27.587L14.0298 25.3059L8.14024 23.0248L2.25068 20.7437C1.88258 20.5965 1.56356 20.3757 1.31816 20.0814C1.07276 19.8116 0.900983 19.4682 0.802824 19.1248C0.704664 18.7814 0.680123 18.4135 0.753743 18.0946C0.827362 17.7512 0.974604 17.4569 1.24454 17.1871L5.36724 13.2625L9.48994 9.31346L13.6126 5.38894L17.7353 1.43988C18.0789 1.12101 18.4715 0.973846 18.8642 0.973846C19.2568 0.973846 19.674 1.09649 20.0421 1.34177V1.31724Z"
            fill="white"
          />
          <path
            d="M160.191 98.0569C159.896 98.2776 159.528 98.3512 159.185 98.2531C158.816 98.1795 158.473 97.9588 158.227 97.6154C157.982 97.272 157.859 96.8795 157.884 96.5116C157.884 96.1437 158.056 95.8003 158.35 95.5795C158.645 95.3588 159.013 95.2852 159.356 95.3833C159.724 95.4569 160.068 95.6776 160.313 96.021C160.559 96.3644 160.681 96.7569 160.657 97.1248C160.657 97.4927 160.485 97.8361 160.191 98.0323V98.0569Z"
            fill="white"
          />
          <path
            d="M162.079 89.055C161.76 89.3002 161.343 89.3738 160.95 89.2757C160.557 89.2021 160.165 88.9569 159.87 88.5889C159.576 88.221 159.453 87.7795 159.478 87.3625C159.502 86.9455 159.674 86.5776 159.993 86.3323C160.312 86.087 160.729 86.0135 161.122 86.1116C161.514 86.2097 161.907 86.4304 162.202 86.7984C162.496 87.1663 162.619 87.6078 162.594 88.0248C162.57 88.4418 162.398 88.8097 162.079 89.055Z"
            fill="white"
          />
          <path
            d="M159.895 79.5135C159.552 79.7833 159.086 79.8568 158.668 79.7587C158.251 79.6606 157.81 79.3908 157.491 78.9983C157.172 78.6059 157.049 78.1153 157.073 77.6493C157.098 77.2078 157.294 76.7908 157.638 76.521C157.981 76.2512 158.448 76.1776 158.865 76.2757C159.306 76.3738 159.724 76.6436 160.043 77.0361C160.362 77.4285 160.484 77.9191 160.46 78.3851C160.435 78.8267 160.239 79.2436 159.895 79.5135Z"
            fill="white"
          />
          <path
            d="M155.331 70.5606C154.938 70.855 154.448 70.9285 153.981 70.8304C153.515 70.7323 153.049 70.438 152.705 69.9965C152.362 69.555 152.214 69.0153 152.239 68.5248C152.263 68.0342 152.484 67.5927 152.877 67.2984C153.27 67.004 153.76 66.9304 154.227 67.0285C154.693 67.1267 155.159 67.421 155.503 67.8625C155.846 68.304 155.994 68.8436 155.969 69.3342C155.944 69.8248 155.724 70.2663 155.331 70.5606Z"
            fill="white"
          />
          <path
            d="M149.417 62.3682C149 62.6871 148.46 62.7852 147.945 62.6625C147.429 62.5644 146.914 62.2455 146.546 61.755C146.178 61.2644 146.006 60.7003 146.055 60.1852C146.08 59.6701 146.325 59.1795 146.742 58.8606C147.159 58.5418 147.699 58.4437 148.215 58.5663C148.73 58.6644 149.245 58.9833 149.613 59.4738C149.981 59.9644 150.153 60.5286 150.104 61.0437C150.08 61.5587 149.834 62.0493 149.417 62.3682Z"
            fill="white"
          />
          <path
            d="M142.668 54.8135C142.227 55.1569 141.638 55.255 141.098 55.1323C140.533 55.0097 139.993 54.6663 139.601 54.1512C139.208 53.6361 139.036 53.0229 139.061 52.4588C139.085 51.8946 139.331 51.3795 139.797 51.0361C140.263 50.6927 140.828 50.5946 141.368 50.7172C141.932 50.8399 142.472 51.1833 142.865 51.6984C143.257 52.2135 143.429 52.8267 143.404 53.3908C143.38 53.955 143.135 54.4701 142.668 54.8135Z"
            fill="white"
          />
          <path
            d="M135.379 47.8719C134.889 48.2399 134.275 48.338 133.686 48.2153C133.097 48.0927 132.508 47.7248 132.091 47.1606C131.674 46.5965 131.478 45.9342 131.502 45.3455C131.551 44.7323 131.797 44.1682 132.287 43.8248C132.778 43.4814 133.392 43.3587 133.981 43.4814C134.57 43.604 135.158 43.9719 135.576 44.5361C135.993 45.1002 136.189 45.7625 136.165 46.3512C136.116 46.9644 135.87 47.5286 135.379 47.8719Z"
            fill="white"
          />
          <path
            d="M127.626 41.4946C127.111 41.887 126.448 41.9852 125.835 41.8625C125.221 41.7399 124.583 41.3474 124.117 40.7342C123.651 40.121 123.454 39.4342 123.503 38.7965C123.553 38.1587 123.822 37.5455 124.338 37.1531C124.853 36.7606 125.491 36.6625 126.129 36.7852C126.767 36.9078 127.381 37.3002 127.847 37.9135C128.313 38.5267 128.51 39.2135 128.46 39.8512C128.411 40.4889 128.141 41.1021 127.626 41.4946Z"
            fill="white"
          />
          <path
            d="M119.527 35.6568C118.988 36.0738 118.3 36.1965 117.613 36.0493C116.926 35.9021 116.288 35.5097 115.797 34.8719C115.307 34.2342 115.11 33.4984 115.135 32.8116C115.184 32.1248 115.478 31.487 116.018 31.0701C116.558 30.6531 117.245 30.5304 117.932 30.6776C118.62 30.8248 119.258 31.2172 119.748 31.855C120.239 32.4927 120.435 33.2285 120.411 33.9153C120.362 34.6021 120.067 35.2399 119.527 35.6568Z"
            fill="white"
          />
          <path
            d="M111.134 30.3833C110.545 30.8248 109.834 30.9474 109.098 30.8003C108.386 30.6531 107.674 30.2116 107.184 29.5493C106.693 28.887 106.447 28.0776 106.496 27.3663C106.545 26.6304 106.864 25.9682 107.429 25.5267C107.993 25.0852 108.73 24.9625 109.466 25.1097C110.177 25.2569 110.889 25.6984 111.38 26.3606C111.871 27.0229 112.116 27.8323 112.067 28.5436C112.018 29.2795 111.699 29.9418 111.134 30.3833Z"
            fill="white"
          />
          <path
            d="M102.447 25.7719C101.834 26.238 101.073 26.3606 100.312 26.2135C99.5516 26.0418 98.8154 25.6002 98.2755 24.8889C97.7357 24.1776 97.4903 23.3436 97.5393 22.5833C97.5884 21.8229 97.932 21.1116 98.5209 20.6455C99.1099 20.1795 99.8952 20.0569 100.656 20.204C101.417 20.3757 102.153 20.8172 102.693 21.5285C103.233 22.2399 103.478 23.0738 103.429 23.8342C103.38 24.5946 103.036 25.3059 102.447 25.7719Z"
            fill="white"
          />
          <path
            d="M93.4915 21.872C92.8535 22.3625 92.0437 22.5097 91.2339 22.338C90.424 22.1663 89.6633 21.7003 89.0989 20.9399C88.5345 20.1795 88.2891 19.321 88.3382 18.5116C88.3872 17.7021 88.7308 16.9663 89.3934 16.4757C90.0559 15.9852 90.8412 15.838 91.651 16.0097C92.4608 16.1814 93.2216 16.6474 93.786 17.4078C94.3504 18.1682 94.6204 19.0267 94.5467 19.8361C94.4977 20.6455 94.1541 21.3814 93.4915 21.872Z"
            fill="white"
          />
          <path
            d="M84.3615 18.855C83.6743 19.3701 82.84 19.5172 82.0056 19.3455C81.1713 19.1738 80.3615 18.6588 79.7725 17.8738C79.1836 17.0889 78.9136 16.1814 78.9627 15.3229C79.0118 14.4644 79.3799 13.6795 80.067 13.1644C80.7541 12.6493 81.5885 12.5022 82.4228 12.6739C83.2572 12.8455 84.067 13.3606 84.6559 14.1455C85.2449 14.9305 85.5148 15.838 85.4658 16.6965C85.4167 17.555 85.0486 18.3399 84.3615 18.8305V18.855Z"
            fill="white"
          />
          <path
            d="M75.0856 16.721C74.374 17.2606 73.4905 17.4078 72.6071 17.2361C71.7237 17.0644 70.8893 16.5248 70.2513 15.6908C69.6132 14.8569 69.3433 13.9002 69.3924 13.0172C69.4414 12.1342 69.8341 11.3002 70.5457 10.7606C71.2574 10.221 72.1408 10.0738 73.0243 10.2701C73.9077 10.4663 74.7421 10.9814 75.3801 11.8153C76.0181 12.6493 76.2881 13.6059 76.239 14.4889C76.1899 15.3719 75.7973 16.2059 75.0856 16.7455V16.721Z"
            fill="white"
          />
          <path
            d="M65.7858 15.4946C65.0496 16.0587 64.1171 16.2059 63.2092 16.0097C62.3012 15.8135 61.3932 15.2738 60.7552 14.3908C60.1171 13.5078 59.7981 12.5267 59.8717 11.5946C59.9208 10.6625 60.338 9.80403 61.0742 9.23988C61.8104 8.67573 62.7429 8.52856 63.6509 8.72479C64.5589 8.92102 65.4668 9.46064 66.1049 10.3437C66.7429 11.2267 67.0619 12.2078 66.9883 13.1399C66.9392 14.072 66.522 14.9304 65.7858 15.4946Z"
            fill="white"
          />
          <path
            d="M56.5341 15.1512C55.7734 15.7399 54.7918 15.9116 53.8348 15.6908C52.8777 15.4701 51.9452 14.9059 51.2581 14.0229C50.5709 13.1399 50.2765 12.0852 50.3256 11.104C50.3746 10.1229 50.8163 9.23987 51.5771 8.65119C52.3378 8.06251 53.3194 7.89082 54.2765 8.11158C55.2335 8.3078 56.166 8.89648 56.8286 9.7795C57.4912 10.6625 57.8102 11.7172 57.7611 12.6984C57.7121 13.6795 57.2703 14.5625 56.5096 15.1512H56.5341Z"
            fill="white"
          />
          <path
            d="M47.3804 15.6418C46.5705 16.255 45.5644 16.4267 44.5583 16.2059C43.5767 15.9852 42.5951 15.3965 41.8834 14.4644C41.1718 13.5323 40.8527 12.4286 40.9264 11.4229C41 10.4172 41.4172 9.48515 42.227 8.87195C43.0368 8.25874 44.0429 8.08704 45.0491 8.3078C46.0307 8.52855 47.0123 9.11724 47.7239 10.0493C48.4356 10.9814 48.7546 12.0852 48.681 13.0908C48.6319 14.0965 48.1902 15.0286 47.3804 15.6418Z"
            fill="white"
          />
          <path
            d="M38.3986 16.9172C37.5642 17.555 36.509 17.7267 35.4783 17.5059C34.4477 17.2852 33.4415 16.672 32.7053 15.6908C31.9692 14.7097 31.6256 13.5814 31.6992 12.5267C31.7728 11.472 32.2145 10.5154 33.0489 9.87762C33.8833 9.23988 34.9385 9.06819 35.9691 9.28894C36.9998 9.5097 38.006 10.1229 38.7422 11.104C39.4784 12.0852 39.8219 13.2135 39.7483 14.2682C39.6747 15.3229 39.233 16.2795 38.3986 16.9172Z"
            fill="white"
          />
          <path
            d="M29.6378 18.9531C28.7789 19.6153 27.6746 19.787 26.5949 19.5663C25.5151 19.3455 24.4844 18.6833 23.6992 17.6776C22.9139 16.6719 22.5949 15.4946 22.6685 14.3908C22.7421 13.287 23.2084 12.2814 24.0673 11.6436C24.9262 11.0059 26.0305 10.8097 27.1102 11.0304C28.19 11.2512 29.2206 11.9135 30.0059 12.9191C30.7912 13.9248 31.1102 15.1021 31.0611 16.2059C30.9875 17.3097 30.5213 18.3153 29.6378 18.9531Z"
            fill="white"
          />
        </g>
      </motion.svg>

      <img src="/section5.png?v=1" alt="section5" />
      <S.Classification>
        <img src="/section5_item1.png?v=1" alt="section5_item1" />
      </S.Classification>
      <S.Classification>
        <img src="/section5_item2.png?v=1" alt="section5_item2" />
      </S.Classification>
      <S.Classification>
        <img src="/section5_item3.png?v=1" alt="section5_item3" />
      </S.Classification>
      <S.Classification>
        <img src="/section5_item4.png?v=1" alt="section5_item4" />
      </S.Classification>
      <S.Classification>
        <img src="/section5_item5.png?v=1" alt="section5_item5" />
      </S.Classification>
    </S.Section5>
  );
};

export default Section5;
