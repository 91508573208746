import styled from "styled-components";

const Btn = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const Section13 = styled.div`
  background-image: url("/section13.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding: min(calc(2.94vw + 23.49px), 80px) min(calc(5.35vw - 22.74px), 80px);
`;

export const Header = styled.div`
  font-size: min(calc(1.69vw + 15.6px), 48px);
  letter-spacing: -0.02em;
  font-weight: 400;
  text-align: center;
  color: #ffffff;

  & > span {
    font-weight: 700;
  }
`;

export const Content = styled.div`
  font-size: min(calc(0.44vw + 16.6px), 25px);
  font-weight: 400;
  line-height: min(calc(1.44vw + 7.4px), 35px);
  letter-spacing: -0.03em;
  text-align: center;
  color: #ffffff;

  margin-top: 3vh;
`;

export const Button = styled(Btn)`
  background-color: #fff;

  font-size: min(calc(0.88vw + 15.2px), 32px);
  font-weight: 700;
  line-height: min(calc(3.23vw + 28.07px), 90px);
  letter-spacing: -0.02em;
  text-align: center;
  color: #1ead8e;

  width: min(400px, 85%);
  height: min(calc(3.23vw + 28.07px), 90px);

  margin: calc(3vw + 3vh) auto 0;

  border-radius: 45px;
`;
