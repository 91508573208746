import * as S from './styles';

import React from 'react';

type Props = {
  onClickJoinButton: () => void;
};

const Nav = ({ onClickJoinButton }: Props) => {
  return (
    <S.Nav>
      <S.LogoContainer>{S.Logo}</S.LogoContainer>
      <S.Menus>
        {/* <S.NavMenu>지원 쇼핑몰</S.NavMenu>
      <S.NavMenu>자주 묻는 질문</S.NavMenu> */}
        <S.Menu onClick={onClickJoinButton}>지금 무료로 입점하기</S.Menu>
      </S.Menus>
    </S.Nav>
  );
};

export default Nav;
