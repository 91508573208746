import * as S from "./styles";

import React from "react";

type Props = {
  onClickJoinButton: () => void;
};

const Banner = ({ onClickJoinButton }: Props) => {
  return (
    <S.Banner>
      <div>여러분의 브랜드를 전세계에 판매하는 가장 쉽고 빠른 방법</div>
      <div>클릭 한번으로 전세계 20개국 진출</div>
      <div>입점형 멀티 채널 판매 솔루션</div>
      <S.JoinBtn onClick={onClickJoinButton}>지금 무료로 입점하기</S.JoinBtn>
    </S.Banner>
  );
};

export default Banner;
