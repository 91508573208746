import styled from 'styled-components';

export const Section4 = styled.div`
  background-color: #ffffff;

  height: calc(12.04vw + 668.83px);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  height: 21rem;

  margin-top: 7vh;

  font-size: min(calc(1.61vw + 17.18px), 48px);
  font-weight: 300;
  line-height: min(calc(1.94vw + 27.76px), 65px);
  letter-spacing: -0.02em;
  text-align: center;

  & > br:nth-of-type(2) {
    display: none;
  }

  @media (max-width: 425px) {
    height: 102px;

    & > br:nth-of-type(2) {
      display: block;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 48.438rem;
  background-color: #e9f8f2;

  display: flex;
  & > div.title {
    color: #00a581;
    align-items: center;
    position: absolute;
    width: 13.125rem;
    height: 1.806rem;
    top: 125rem;
    left: 40.625rem;
    gap: 0px;
    opacity: 0px;

    white-space: nowrap;

    font-size: 35px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;

    margin-top: 30%;

    @media (max-width: 780px) {
      & > div:first-of-type {
        font-size: 30px;
      }
    }
  }

  & > section.right {
    display: flex;
    justify-content: center;
    padding: 10vh 0;
    margin-top: 6vh;

    width: 100%;

    div#map {
      width: 20%;
      height: 100%;

      background-image: url('/section4_map.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      position: relative;

      @media (max-width: 425px) {
        width: 75%;
      }
    }
  }

  @media (max-width: 620px) {
    margin-top: 2vh;

    flex-direction: column;

    & > section.left {
      width: 100%;
      height: 120px;

      & > div:first-of-type {
        font-size: 20px;
        line-height: 24px;
        margin-top: 5%;
      }
      & > div:nth-of-type(2) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    & > section.right {
      width: 100%;
      height: 300px;

      margin-top: 20%;
    }
  }
`;

export const Classification = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #969696;

  color: #ffffff;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  &:hover {
    background-color: #1ead8e;
    & > img {
      animation-name: fadeIn;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
  @keyframes fadeIn {
    to {
      scale: 1.2;
    }
  }

  & > img {
    scale: 0.8;

    position: absolute;

    width: 200px;
    @media (max-width: 960px) {
      width: 179.2px;
    }
  }

  &:first-of-type {
    /* background-color: #1ead8e; */
    top: 4%;
    right: 13%;
    & > img {
      left: 100%;
      top: -80%;
    }
    @media (max-width: 1150px) {
      top: 3.5%;
      right: 7.5%;
    }
    @media (max-width: 960px) {
      right: 4%;
    }
    @media (max-width: 700px) {
      top: 10%;
      & > img {
        left: 110%;
      }
    }
    @media (max-width: 620px) {
      top: 5%;
      right: 7%;
      & > img {
        left: 80%;
        top: unset;
        bottom: 110%;
        width: 125px;
      }
    }
  }
  &:nth-of-type(2) {
    top: 42%;
    right: 13%;
    & > img {
      right: -200%;
      top: -50%;
      width: 200px;
    }

    @media (max-width: 1150px) {
      right: -1%;
    }
    @media (max-width: 960px) {
      right: -7%;
      & > img {
        right: -200%;
      }
    }
    @media (max-width: 800px) {
      & > img {
        top: 100%;
      }
    }
    @media (max-width: 620px) {
      top: 40%;
      right: 0%;
      & > img {
        right: unset;
        left: 120%;
        top: 50%;
        width: 125px;
      }
    }
  }
  &:nth-of-type(3) {
    bottom: 10%;
    left: 42%;
    & > img {
      left: 100%;
      top: 40%;
    }

    @media (max-width: 1150px) {
      bottom: 5%;
    }
    @media (max-width: 960px) {
      bottom: 2%;
    }
    @media (max-width: 800px) {
      bottom: 10%;
    }
    @media (max-width: 700px) {
      bottom: 13%;
    }
    @media (max-width: 620px) {
      bottom: 2%;
      left: 40%;
      & > img {
        left: -40%;
        top: 120%;
        width: 125px;
      }
    }
    @media (max-width: 380px) {
      & > img {
        left: 50%;
      }
    }
  }
  &:nth-of-type(4) {
    /* background-color: #1ead8e; */
    bottom: 23%;
    left: 18%;
    & > img {
      right: 100%;
      top: -100%;
    }

    @media (max-width: 1150px) {
      left: 5%;
    }
    @media (max-width: 960px) {
      left: 1%;
    }
    @media (max-width: 800px) {
      bottom: 30%;
      left: 6%;
    }
    @media (max-width: 700px) {
      left: 0;
    }
    @media (max-width: 620px) {
      bottom: 23%;
      left: 4%;
      & > img {
        right: 40%;
        top: 115%;
        width: 125px;
      }
    }
    @media (max-width: 380px) {
      & > img {
        right: 20%;
        top: 125%;
      }
    }
  }
  &:nth-of-type(5) {
    top: 17%;
    left: 32%;
    & > img {
      left: -170%;
      bottom: 90%;
      width: 200px;
    }

    @media (max-width: 1300px) {
      top: 14%;
      left: 25%;
    }
    @media (max-width: 1150px) {
      left: 17.5%;
    }
    @media (max-width: 960px) {
      top: 15%;
      left: 10%;
      & > img {
        width: 195.2px;
        left: -230%;
      }
    }
    @media (max-width: 800px) {
      & > img {
        left: -260%;
      }
    }
    @media (max-width: 700px) {
      left: 5%;
    }
    @media (max-width: 620px) {
      top: 13%;
      left: 15%;
      & > img {
        left: -110%;
        bottom: 110%;
        width: 125px;
      }
    }
  }

  @media (max-width: 800px) {
    width: 80px;
    height: 80px;

    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 620px) {
    width: 66px;
    height: 66px;

    font-size: 16px;
    line-height: 20px;

    background-color: #1ead8e;
    & > img {
      display: block;

      scale: 1;
      opacity: 1;
    }

    &:hover {
      & > img {
        animation-name: unset;
      }
    }
  }
`;
