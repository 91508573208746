import styled from 'styled-components';

export const Item = styled.div`
  color: #000e33;
  line-height: calc(0.67vw + 19.61px);
  letter-spacing: -0.02em;
  text-align: center;

  & + & {
    margin-left: 8vw;
  }

  .transform-origin-Bottom {
    transform-origin: bottom;
  }

  & > div:nth-of-type(2) {
    font-size: calc(0.7vw + 6.6px);
    font-weight: 400;
    white-space: nowrap;
  }
  & > div:nth-of-type(3) {
    font-size: calc(1.16vw + 7.67px);
    font-weight: 700;
    white-space: nowrap;
  }
  div#graphValue {
    position: absolute;
    top: 15%;
    right: 22.5%;

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #ffffff;

    @media (max-width: 1100px) {
      display: none;
    }
    @media (min-width: 1800px) {
      right: 25%;
    }
    @media (min-width: 2100px) {
      right: 27%;
    }
  }
`;

export const Section1 = styled.div`
  background: rgba(215, 236, 231, 0.5);

  padding: calc(0.1vh + 60px);

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  & > ${Item} {
    img {
      max-width: 176px;
      width: calc(11.08vw + 46.02px);
      max-height: 133px;
      height: calc(7.45vw + 45.89px);
    }
  }

  & > ${Item}:first-of-type {
    & > img {
      max-width: 133px;
      width: calc(8.02vw + 52.54px);
    }
    & > div:nth-of-type(1) {
      font-size: calc(0.7vw + 6.6px);
      font-weight: 400;
      white-space: nowrap;
    }
    & > div:nth-of-type(2) {
      font-size: calc(1.16vw + 7.67px);
      font-weight: 700;
      white-space: nowrap;
    }
  }
`;
