import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section9 = () => {
  const { scrollY } = useScroll();
  const [isSaleGrowHight, setIsSaleGrowHight] = useState(false);
  const [isSaleStartHight, setIsSaleStartHight] = useState(false);

  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 5470) {
        setIsSaleGrowHight(true);
      }
      if (latest > 5800) {
        setIsSaleStartHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section9>
      <S.PContent>
        <span style={{ position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '190px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-10px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isSaleGrowHight
                ? { opacity: 1, clipPath: 'inset(0 0 0 0)' }
                : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          매출 성장
        </span>
        으로의
        <br />
        가장 쉽고 빠른 여정은 다음과 같습니다.
      </S.PContent>
      <S.SectionContainer>
        <S.Section>
          <S.SectionTitle>
            <span>1 문의하기</span>
          </S.SectionTitle>
          <S.SectionContent>
            <span>'지금 바로 문의하기'</span>버튼을 통해 <br />
            온라인&오프라인 미팅을 신청해주세요.
          </S.SectionContent>
        </S.Section>
        <S.Section>
          <S.SectionTitle>
            <span>2 온라인/ 오프라인 미팅 진행</span>
          </S.SectionTitle>
          <S.SectionContent>
            상담을 통해 여러분 상품에 <span>가장 잘 맞는</span>
            <br />
            <span>판로 및 판매 형태</span>를 확정합니다.
          </S.SectionContent>
        </S.Section>
      </S.SectionContainer>
      <S.PContent>
        저스트큐 입점 한번으로{' '}
        <span style={{ position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '340px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-8px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isSaleStartHight
                ? { opacity: 1, clipPath: 'inset(0 0 0 0)' }
                : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          전세계 판매 시작 !
        </span>
      </S.PContent>
    </S.Section9>
  );
};

export default Section9;
