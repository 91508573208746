import "./styles.css";

import React, { Component } from "react";

const ModalSize = {
  default: "",
  large: "sh-modal--large",
};

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  isOpened: boolean;
  onClose: () => void;
  header: string;
  size?: keyof typeof ModalSize;
};

type State = {
  willUnshow: boolean;
};

class Modal extends Component<Props, State> {
  state: State = {
    willUnshow: false,
  };

  componentDidMount = () => {
    if (this.props.isOpened) {
      document.body.style.overflow = "hidden";
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.isOpened === false && this.props.isOpened === true) {
      this.setState({ willUnshow: false });
    }
  };

  componentWillUnmount = () => {
    document.body.style.overflow = "unset";
  };

  close = () => {
    this.setState({ willUnshow: true }, async () => {
      await new Promise((r) => setTimeout(r, 0.3 * 1000)); // animation 시간 0.3초
      this.props.onClose();
    });
  };

  render() {
    const { isOpened, className, onClose, header, size, ...rest } = this.props;
    const { willUnshow } = this.state;
    const className_ = `sh-modal ${className ?? ""} ${
      ModalSize[size || "default"]
    } ${isOpened ? "openModal" : ""} ${willUnshow ? "closing" : ""}`
      .replace(/\s\s+/g, " ")
      .trim();

    return (
      <div {...rest} className={className_} onClick={this.close}>
        {isOpened && (
          <section
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <header>
              {header}
              <button className="close" onClick={this.close}>
                &times;
              </button>
            </header>
            <main>{this.props.children}</main>
            <footer>
              <button className="close" onClick={this.close}>
                닫기
              </button>
            </footer>
          </section>
        )}
      </div>
    );
  }
}

export default Modal;
