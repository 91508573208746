import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section3 = () => {
  const { scrollY } = useScroll();
  const [isBalloonAnimation, setIsBalloonAnimation] = useState(false);
  const [isHight, setIsHight] = useState(false);

  const balloonAnimate = {
    true: {
      scale: [1, 1.2, 1],
      backgroundColor: ['#fff', '#1ead8e', '#fff'],
      color: '#000',
      transition: { duration: 3, ease: 'easeInOut', repeat: Infinity },
    },
    false: {
      scale: 1,
      backgroundColor: '#fff',
      color: '#000',
    },
    mainBalloonFalse: {
      scale: 1,
      backgroundColor: '#1ead8e',
      color: '#fff',
    },
    mainBalloonTrue: {
      scale: [1, 1.2, 1],
      backgroundColor: '#1ead8e',
      transition: { duration: 3, ease: 'easeInOut', repeat: Infinity },
    },
  };

  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 1000 && latest < 2000) {
        setIsBalloonAnimation(true);
      } else {
        setIsBalloonAnimation(false);
      }
      if (latest > 1300) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section3>
      <S.Section3Header>
        국내외 판로 확장
        <br />
        <span style={{ fontWeight: '700', position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '360px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-10px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          고려하셔야 할 것들
        </span>
        이 너무 많았을 겁니다.
      </S.Section3Header>
      <S.Section3BalloonContainer>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          수시로 <span>변하는 해외</span>
          <br />
          <span>각 국가별 정책</span> 파악 어려움
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          담당 <span>인력 채용 어려움</span>
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          신뢰할 수 있는 <span>현지 파트너의 부재</span>
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          주의해야 할 <span>문화적 특성</span>
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          <span>주문 배송 어려움</span>
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          <span>언어 장벽</span>
        </S.Section3Balloon>
        <S.Section3Balloon
          initial={'false'}
          animate={isBalloonAnimation ? 'true' : undefined}
          transition={
            isBalloonAnimation
              ? { duration: 3, ease: 'easeInOut', repeat: Infinity }
              : undefined
          }
          variants={balloonAnimate}
        >
          큰 투자 대비
          <br />
          <b>잘 판매될지에 대한 불확실성</b>
        </S.Section3Balloon>
      </S.Section3BalloonContainer>
    </S.Section3>
  );
};

export default Section3;
