import * as S from "./styles";

import React from "react";

type Props = {
  onClickJoinButton: () => void;
};

const Section13 = ({ onClickJoinButton }: Props) => {
  return (
    <S.Section13>
      <S.Header>
        여러분의 브랜드를 전세계에 판매하는
        <br />
        <span>가장 쉽고 빠른 방법</span>
      </S.Header>
      <S.Content>클릭 한번으로 전세계에 판매 시작</S.Content>
      <S.Button onClick={onClickJoinButton}>지금 무료로 입점하기</S.Button>
    </S.Section13>
  );
};

export default Section13;
