import * as S from './styles';

import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView, useScroll } from 'framer-motion';

const Section11 = () => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 'some' });
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);

  useEffect(() => {
    if (isInView) {
      controls.start({
        x: ['0%', '-100%'],
        transition: {
          duration: 10,
          ease: 'linear',
          repeat: Infinity,
        },
      });
    } else {
      controls.start({});
    }
  }, [controls, isInView]);
  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 7100) {
        setIsHight(true);
      }
    });
  }, [scrollY]);

  return (
    <S.Section11>
      <S.Header>
        이미{' '}
        <span style={{ position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '300px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-5px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          수많은 브랜드사
        </span>
        와 함께 합니다.
      </S.Header>
      <S.ImageRow animate={controls} ref={ref}>
        <S.Image
          key={'/section11.png?v=1_1'}
          src="/section11.png?v=1"
          alt="/section11.png?v=1"
        />
        {isInView && (
          <S.Image
            key={'/section11.png?v=1_2'}
            src="/section11.png?v=1"
            alt="/section11.png?v=1"
          />
        )}
      </S.ImageRow>
    </S.Section11>
  );
};

export default Section11;
