import React, { useState } from 'react';

import Banner from './Banner';
import Footer from './Footer';
import Modal from '../shared/components/Modal';
import Nav from './Nav';
import Section1 from './Section1';
import Section10 from './Section10';
import Section11 from './Section11';
import Section12 from './Section12';
import Section13 from './Section13';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';
import Section8 from './Section8';
import Section9 from './Section9';
import styled from 'styled-components';

type State = {
  isModalOpened: boolean;
};

const Home = () => {
  const [isModalOpened, setIsModalOpened] =
    useState<State['isModalOpened']>(false);

  return (
    <Page>
      <Modal
        isOpened={isModalOpened}
        header="입점하기"
        onClose={() => {
          setIsModalOpened(false);
        }}
      >
        입점 문의는 product_proposal@justq.co.kr로 문의 부탁드립니다.
      </Modal>
      <Nav
        onClickJoinButton={() => {
          setIsModalOpened(true);
        }}
      />
      <Banner
        onClickJoinButton={() => {
          setIsModalOpened(true);
        }}
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13
        onClickJoinButton={() => {
          setIsModalOpened(true);
        }}
      />
      <Footer />
    </Page>
  );
};

export default Home;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  font-family: Pretendard Variable;
`;
