import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section7 = () => {
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);
  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 4170) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section7>
      <div>
        <span style={{ position: 'relative' }}>
          {' '}
          저스트큐
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '200px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-10px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
        </span>
        와 함께라면 걱정 없습니다.
      </div>
      <div style={{ position: 'relative' }}>
        당신의 글로벌 판매 파트너
        <motion.img
          src="/boldHight.png?v=1"
          alt="/boldHight.png?v=1"
          style={{
            width: '500px',
            height: '24px',
            position: 'absolute',
            bottom: '0px',
            left: '-10px',
          }}
          initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
          animate={
            isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
          }
          transition={{ duration: 1, ease: 'easeOut' }}
        />
      </div>
      <div>
        저스트큐의 미션은 가장 쉽고 빠른 <span>전세계 판로 확장</span>을 통해
        <br />
        여러분 브랜드를 성장시키는 것입니다.
      </div>
    </S.Section7>
  );
};

export default Section7;
