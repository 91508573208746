import * as S from './styles';

import React from 'react';

const Section1 = () => {
  return (
    <S.Section1>
      <S.Item>
        <img src={'/section1_item1.png?v=1'} alt="image1"></img>
        <div>5천만 ~ 78억명으로 상승</div>
        <div>잠재 소비자 수</div>
      </S.Item>
      <S.Item style={{ position: 'relative' }}>
        <div className="transform-origin-Bottom">
          <img src="/section1_item2.png?v=1" alt="image2" />
          <div id="graphValue">267%</div>
        </div>
        <div>0% ~ 267%로 상승</div>
        <div>평균 매출 상승률</div>
      </S.Item>
      <S.Item>
        <div className="transform-origin-Bottom">
          <img src="/section1_item3.png?v=1" alt="image3" />
        </div>
        <div>27명 ~ 0명으로 감소</div>
        <div>필요한 해외 전문 인력</div>
      </S.Item>
    </S.Section1>
  );
};

export default Section1;
