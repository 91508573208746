import React from 'react';
import styled from 'styled-components';

export const Section10 = styled.div`
  height: calc(850px - 13vh);

  background-color: #eaeaea;

  padding-top: 13vh;

  @media (max-width: 425px) {
    padding-top: 6vh;
  }
`;

export const Header = styled.div`
  font-size: min(calc(1.25vw + 24px), 48px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: min(calc(2.81vw + 11px), 65px);
  text-align: center;

  & > span {
    font-weight: 700;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: min(calc(38.64vw + 218.18px), 960px);

  margin: 5vh auto 0;

  @media (max-width: 500px) {
    width: 85%;
    height: 70vw;

    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

export const Item = styled.div`
  width: min(calc(6.44vw + 61.36px), 185px);
  height: min(calc(6.44vw + 61.36px), 185px);

  box-sizing: border-box;

  border: 1px solid #1ead8e;
  border-radius: 93px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  user-select: none;

  & > svg {
    width: min(calc(1.74vw + 30px), 65px);
    height: min(calc(1.52vw + 29px), 60px);
  }

  & > div {
    font-size: min(calc(0.45vw + 16px), 26px);
    font-weight: 400;
    letter-spacing: -0.02em;

    margin-top: 10px;
  }

  &::after {
    content: '';
    width: min(calc(1.29vw + 10.27px), 35px);
    height: min(calc(1.29vw + 10.27px), 35px);

    background-color: #eaeaea;

    display: block;
    position: absolute;
    top: 2.7%;
    right: 6.49%;
  }

  /* @media (hover: hover) {
    &:hover {
      background-color: #1ead8e;
      color: #ffffff;
      border-color: #1ead8e;
      &::after {
        width: 0;
        height: 0;
      }
      svg,
      path {
        fill: #1ead8e;
        stroke: #ffffff;
      }
      & > div {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.02em;
      }
    }
  } */

  @media (max-width: 500px) {
    width: 30vw;
    height: 30vw;

    & > svg {
      width: 11vw;
      height: 12vw;
    }
    & > div {
      font-size: 20px;
    }
  }
`;

export const BottomSection = styled.div`
  margin-left: 15%;
  margin-top: 7.5%;
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
    margin-left: 5%;
  }
`;

export const Interview = styled.div`
  width: 25%;

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const InterviewHeader = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.02em;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const InterviewContent = styled.div`
  font-size: min(calc(0.7vw + 12px), 22px);
  font-weight: 400;
  line-height: calc(0.1vw + 32px);
  letter-spacing: -0.02em;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const InterviewButtons = styled.div`
  display: flex;

  margin-top: 5%;
  margin-left: 1%;

  & > div {
    cursor: pointer;
  }

  & > div:last-of-type {
    margin-left: 15px;
  }

  @media (max-width: 500px) {
    margin-bottom: 5%;
  }
`;

export const InterviewPrevButton = (
  <svg
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.353553"
      y1="10.6464"
      x2="11.3536"
      y2="21.6464"
      stroke="#202020"
    />
    <line
      y1="-0.5"
      x2="15.5563"
      y2="-0.5"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0 11)"
      stroke="#202020"
    />
  </svg>
);

export const InterviewNextButton = (
  <svg
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-0.5"
      x2="15.5563"
      y2="-0.5"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 12 11)"
      stroke="#202020"
    />
    <line
      x1="11.6464"
      y1="11.3536"
      x2="0.646447"
      y2="0.353554"
      stroke="#202020"
    />
  </svg>
);

export const Photos = styled.div`
  display: flex;
  margin-left: 3%;
  width: 75%;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 97%;
  }
`;

export const Photo = styled.div`
  & > img {
    width: min(calc(25.81vw + 26.36px), 422px);
    height: min(calc(19.23vw + 19.64px), 312px);
    margin-left: 2vw;
  }
`;

export const Item1Icon = (
  <svg
    width="56"
    height="50"
    viewBox="0 0 56 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2765 6.79815L6.81739 18.964C5.72754 20.0282 5.72754 21.7536 6.81739 22.8115L23.6746 39.2719L29.6527 45.1093C30.7232 46.1546 32.4644 46.3246 33.6316 45.3801C34.9729 44.297 35.0439 42.3575 33.8444 41.1862C34.9794 42.2945 36.8109 42.2945 37.9459 41.1862C39.0808 40.078 39.0808 38.2896 37.9459 37.1813C39.0808 38.2896 40.9123 38.2896 42.0473 37.1813C43.1823 36.0731 43.1823 34.2847 42.0473 33.1764C43.1823 34.2847 45.0138 34.2847 46.1488 33.1764C47.2837 32.0681 47.2837 30.2798 46.1488 29.1715L44.098 27.1691L23.2167 6.79815C22.1269 5.73395 20.3599 5.73395 19.2765 6.79815Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.2226 43.1417C20.0988 44.2655 20.0978 46.0865 21.2203 47.2091C22.3429 48.3317 24.164 48.3307 25.2878 47.2068L29.2716 43.223C30.3954 42.0992 30.3964 40.2782 29.2738 39.1556C28.1513 38.033 26.3302 38.034 25.2064 39.1579L21.2226 43.1417Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M17.1562 39.0716C16.0324 40.1954 16.0314 42.0165 17.1539 43.139C18.2765 44.2616 20.0976 44.2606 21.2214 43.1368L25.2052 39.153C26.329 38.0292 26.33 36.2081 25.2074 35.0855C24.0849 33.963 22.2638 33.964 21.14 35.0878L17.1562 39.0716Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.0888 35.0069C11.965 36.1307 11.964 37.9518 13.0865 39.0743C14.2091 40.1969 16.0302 40.1959 17.154 39.0721L21.1378 35.0883C22.2616 33.9645 22.2626 32.1434 21.1401 31.0208C20.0175 29.8983 18.1964 29.8993 17.0726 31.0231L13.0888 35.0069Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M9.02142 30.9332C7.8976 32.057 7.89659 33.878 9.01916 35.0006C10.1417 36.1232 11.9628 36.1222 13.0866 34.9983L17.0704 31.0146C18.1942 29.8907 18.1952 28.0697 17.0727 26.9471C15.9501 25.8245 14.129 25.8255 13.0052 26.9494L9.02142 30.9332Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M33.7575 6.79084L30.6316 9.9979L28.7098 11.9696L25.0851 15.6882L25 25.1971C25 26.7757 26.2711 28.0485 27.8219 27.9986C29.3727 27.9487 30.4735 26.6134 30.4735 25.0785V21.9713C30.4735 19.7563 31.8114 17.8595 33.6967 17.0796L44.1814 27.8364L49.2292 22.6576C50.2569 21.6032 50.2569 19.8936 49.2292 18.8454L37.4795 6.79084C36.4517 5.73639 34.7853 5.73639 33.7636 6.79084H33.7575Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M34 42L29 37"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M37 37L33 33"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M42 33L37 29"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M14.6671 1.43129L1.87012 14.2283L6.63168 18.9898L19.4287 6.19285L14.6671 1.43129Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M37.0673 5.76137L49.8643 18.5583L54.6258 13.7968L41.8288 0.999809L37.0673 5.76137Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export const Item2Icon = (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48 31C46.7046 35.4658 43.8387 39.3185 40 42L48 31Z"
      fill="white"
    />
    <path
      d="M48 31C46.7046 35.4658 43.8387 39.3185 40 42"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M22 4C23.7765 4 25.641 4 27.555 4C29.469 4 31.2565 4 33 4"
      fill="white"
    />
    <path
      d="M22 4C23.7765 4 25.641 4 27.555 4C29.469 4 31.2565 4 33 4"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M14 42C10.674 39.2382 8.18324 35.4094 7 31L14 42Z" fill="white" />
    <path
      d="M14 42C10.674 39.2382 8.18324 35.4094 7 31"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25 20.6353C22.5202 14.7843 18.5795 11 14.1429 11C7.84097 11 2.53639 18.6395 1 29H19.0593"
      fill="white"
    />
    <path
      d="M25 20.6353C22.5202 14.7843 18.5795 11 14.1429 11C7.84097 11 2.53639 18.6395 1 29H19.0593"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M14 13.1821L11.4982 17L8 13.1821L9.95941 8H14V13.1821Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M14 13.1821L16.0868 17L19 13.1821L17.3703 8H14V13.1821Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M14 13C17.3137 13 20 10.3137 20 7C20 3.68629 17.3137 1 14 1C10.6863 1 8 3.68629 8 7C8 10.3137 10.6863 13 14 13Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M30 20.379C32.497 14.6753 36.4076 11 40.7949 11C47.1266 11 52.4563 18.6395 54 29H35.4381"
      fill="white"
    />
    <path
      d="M30 20.379C32.497 14.6753 36.4076 11 40.7949 11C47.1266 11 52.4563 18.6395 54 29H35.4381"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41 13.1821L43.5042 17L47 13.1821L45.0443 8H41V13.1821Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41 13.1821L38.9151 17L36 13.1821L37.6328 8H41V13.1821Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41 13C44.3137 13 47 10.3137 47 7C47 3.68629 44.3137 1 41 1C37.6863 1 35 3.68629 35 7C35 10.3137 37.6863 13 41 13Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 32C21.5064 32 16.4612 39.2151 15 49H40C38.5388 39.2151 33.4988 32 27.5 32Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M27 34.1769L24.9151 38L22 34.1769L23.6328 29H27V34.1769Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M27 34.1769L29.5042 38L33 34.1769L31.0443 29H27V34.1769Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 35C31.0899 35 34 31.866 34 28C34 24.134 31.0899 21 27.5 21C23.9101 21 21 24.134 21 28C21 31.866 23.9101 35 27.5 35Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export const Item3Icon = (
  <svg
    width="56"
    height="50"
    viewBox="0 0 56 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.542 4.09564H55.44V27.6954H17.5508V4.09564H35.6V0"
      fill="white"
    />
    <path
      d="M37.542 4.09564H55.44V27.6954H17.5508V4.09564H35.6V0"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M17.5508 29.876H55.44"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M36.4961 29.876V38.7535"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M28.8399 29.876L23.2656 40.9508"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M44.1465 29.876L49.7207 40.9508"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.4243 29.5496C8.29413 29.5496 2.30013 37.9954 0.55957 49.4466H30.2833C28.5428 37.9954 22.5488 29.5496 15.4187 29.5496H15.4243Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.4241 32.1452L12.6705 36.584L8.81445 32.1452L10.9748 26.1235H15.4241V32.1452Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.4238 32.1452L18.1774 36.584L22.0279 32.1452L19.8732 26.1235H15.4238V32.1452Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15.4242 32.1453C19.464 32.1453 22.739 28.0815 22.739 23.0685C22.739 18.0555 19.464 13.9917 15.4242 13.9917C11.3843 13.9917 8.10938 18.0555 8.10938 23.0685C8.10938 28.0815 11.3843 32.1453 15.4242 32.1453Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M16.8685 38.0784H13.9806L12.9229 49.4465H15.4246H17.9207L16.8685 38.0784Z"
      fill="#202020"
    />
    <path
      d="M26.0352 10.405C26.0352 10.405 32.9358 21.4965 46.1383 21.2806L43.1497 18.8509L42.1871 23.5L45.573 22.2768"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M26.8301 21.2862C26.8301 21.2862 33.7307 10.1948 46.9332 10.4106L42.5622 8.09717L43.6312 13.344L46.1385 11.3626"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export const Item4Icon = (
  <svg
    width="51"
    height="55"
    viewBox="0 0 51 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5005 54.4219C27.1318 54.4219 28.4542 53.5342 28.4542 52.4392C28.4542 51.3442 27.1318 50.4565 25.5005 50.4565C23.8693 50.4565 22.5469 51.3442 22.5469 52.4392C22.5469 53.5342 23.8693 54.4219 25.5005 54.4219Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M32.2588 41.6706C33.1044 38.5261 34.5117 35.5377 36.365 32.8267C37.8013 30.7226 38.6411 28.1851 38.6411 25.4452C38.6411 18.029 32.4789 12.0521 24.9847 12.3354C18.1797 12.5955 12.6314 18.1273 12.365 24.9192C12.2492 27.8382 13.0948 30.555 14.6063 32.7862C16.4654 35.5203 17.8901 38.503 18.7415 41.6706"
      fill="white"
    />
    <path
      d="M32.2588 41.6706C33.1044 38.5261 34.5117 35.5377 36.365 32.8267C37.8013 30.7226 38.6411 28.1851 38.6411 25.4452C38.6411 18.029 32.4789 12.0521 24.9847 12.3354C18.1797 12.5955 12.6314 18.1273 12.365 24.9192C12.2492 27.8382 13.0948 30.555 14.6063 32.7862C16.4654 35.5203 17.8901 38.503 18.7415 41.6706"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M19.124 43.2659C19.2745 44 19.3962 44.7456 19.483 45.4971L19.5699 46.2601V49.9942C19.5699 51.3468 20.6645 52.4393 22.0197 52.4393H28.9753C30.3305 52.4393 31.4251 51.3468 31.4251 49.9942V46.2601L31.512 45.4971C31.5989 44.7456 31.7205 44.0058 31.8711 43.2659H19.1182H19.124Z"
      fill="white"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M21.8574 45.8613H31.4771"
        stroke="#202020"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M21.8574 48.4451H31.4771"
        stroke="#202020"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M27.6832 22.4971H24.7469C23.9593 22.4971 23.3222 23.1329 23.3222 23.919V24C23.3222 24.7861 23.9593 25.4219 24.7469 25.4219H26.2527C27.0403 25.4219 27.6774 26.0578 27.6774 26.8439C27.6774 27.63 27.0403 28.2659 26.2527 28.2659H23.3164"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 20.6995V30.0578"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25.5005 31.7457C29.0221 31.7457 31.8769 28.8963 31.8769 25.3815C31.8769 21.8667 29.0221 19.0173 25.5005 19.0173C21.9789 19.0173 19.124 21.8667 19.124 25.3815C19.124 28.8963 21.9789 31.7457 25.5005 31.7457Z"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 0V7.78035"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M9.85156 5.0752L14.4326 11.37"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M0.179688 18.3584L7.59281 20.763"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M0.179688 34.7862L7.59281 32.3816"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M50.8204 34.7862L43.4072 32.3816"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M50.8204 18.3584L43.4072 20.763"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41.1485 5.0752L36.5674 11.37"
      stroke="#202020"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
