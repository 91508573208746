import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Section11 = styled.div`
  height: 563px;
  background-color: #ffffff;

  padding: 12vh 0;
`;

export const Header = styled.div`
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 65px;
  text-align: center;

  & > span {
    font-weight: 700;
  }
`;

export const ImageRow = styled(motion.div)`
  display: flex;
  padding: 3vh 0;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`;
