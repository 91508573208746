import styled from "styled-components";

const Btn = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const Banner = styled.div`
  background-image: url("/banner_background.png");
  background-size: cover;
  background-position: center;

  letter-spacing: -0.02em;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;

  padding-top: min(calc(2.34vw + 50.05px), 95px);
  padding-bottom: min(calc(5.44vw + 20.6px), 125px);

  & > div:first-of-type {
    font-size: min(calc(0.31vw + 13px), 19px);
    font-weight: 400;
    line-height: min(calc(0.5vw + 20.4px), 30px);
    opacity: 70%;
  }
  & > div:nth-of-type(2) {
    font-size: min(calc(1.69vw + 17.6px), 50px);
    font-weight: 700;
    line-height: min(calc(1.12vw + 38.4px), 60px);
    margin-top: 1vh;
  }
  & > div:nth-of-type(3) {
    font-size: min(calc(0.4vw + 20.29px), 28px);
    font-weight: 400;
    line-height: min(calc(0.47vw + 24.01px), 33px);
    margin-top: 1vh;
  }
`;

export const JoinBtn = styled(Btn)`
  width: 340px;
  height: 80px;
  background-color: #ffffff;
  font-size: 27px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #1ead8e;
  border-radius: 61px;

  margin-top: 5vh;

  @media (max-width: 425px) {
    width: 191px;
    height: 56px;
    font-size: 20px;
    line-height: 56px;
  }
  @media (max-width: 390px) {
    width: 234px;
    height: 6.5vh;
    font-size: 20px;
    line-height: 6.5vh;
  }
`;
