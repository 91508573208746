import { motion } from 'framer-motion';
import styled from 'styled-components';
export const Section12 = styled.div`
  height: 512px;

  background-color: #ffffff;

  padding: 10vh 0;
`;

export const Header = styled.div`
  font-size: min(calc(1.46vw + 19.89px), 48px);
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: min(calc(1.78vw + 30.74px), 65px);
  text-align: center;

  & > span {
    font-weight: 700;
  }
`;

export const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2vh auto;
  width: 80%;
  gap: 0.5vw;
  @media (max-width: 670px) {
    width: 88%;
  }
  @media (max-width: 530px) {
    width: 92%;
  }
  @media (max-width: 425px) {
    flex-wrap: wrap;
    justify-content: space-evenly;

    width: 95%;
  }
`;

export const Item = styled.div`
  width: 220px;
  height: 341px;
  border: 0.5px solid #bebebe;

  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  user-select: none;
  position: relative;

  .imageContainer {
    width: 250px;
    height: 230px;
    transition: width 0.3s ease, height 0.3s ease;
  }

  .image {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .text {
    font-size: min(calc(0.85vw + 8.74px), 25px);
    font-weight: 400;
    line-height: min(calc(0.85vw + 18.74px), 35px);
    letter-spacing: -0.03em;
    margin: 0.5rem;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  @media (hover: hover) {
    &:hover {
      .imageContainer {
        width: 100%;
        height: 100%;
        transition: width 0.3s ease, height 0.3s ease;
      }
      .text {
        color: white;
        font-size: min(calc(0.85vw + 8.74px), 33px);
        font-weight: 700;
        line-height: min(calc(0.85vw + 18.74px), 65px);
        letter-spacing: -0.02em;
      }
    }
  }

  @media (max-width: 425px) {
    /* font-size: 18px; */
    /* line-height: 28px; */

    width: 30%;
    height: 10vh;
  }
  @media (max-width: 390px) {
    /* font-size: 1rem; */

    width: 27%;
  }
`;
export const itemCard = styled(motion.div)`
  width: 250px;
  height: 230px;
`;
