import * as S from './styles';

import React, { useEffect, useRef, useState } from 'react';
import {
  animate,
  motion,
  useAnimation,
  useInView,
  useMotionValue,
  useTransform,
} from 'framer-motion';

const Section6 = () => {
  const NumberCounter = ({
    start,
    end,
    duration,
  }: {
    start: number;
    end: number;
    duration: number;
  }) => {
    // 숫자 애니메이션 값을 위한 motion value
    const count = useMotionValue(start);
    const rounded = useTransform(count, (value) => Math.round(value));
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
      if (isInView) {
        animate(count, end, { duration, ease: 'linear' });
      }
    }, [isInView, controls, count, end, duration]);

    return <motion.span ref={ref}>{rounded}</motion.span>;
  };

  return (
    <S.Section6>
      <S.Content>
        <motion.img
          src="/section6.png?v=1"
          alt="/section6.png?v=1"
          initial={{ y: '100%', opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        />
        <div>더 많은 소비자</div>
        <div>더 많은 매출</div>
        <div>
          매출 평균{' '}
          <span>
            {' '}
            <NumberCounter start={0} end={267} duration={1} />% 상승
          </span>
        </div>
      </S.Content>
    </S.Section6>
  );
};

export default Section6;
