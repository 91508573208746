import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section4 = () => {
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);

  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 1870) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section4>
      <S.Header>
        전세계 80억 인구는
        <br />
        <span style={{ fontWeight: '800', position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '580px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-10px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          귀사의 브랜드 제품을 경험하기
        </span>
        를 <br />
        기다리고 있습니다.
      </S.Header>
      <S.Content>
        <div className="title">국내 판로 확장</div>
        <section className="right">
          <div id="map">
            <S.Classification>
              오픈마켓
              <img src="/section4_popup2.png?v=1" alt="section4_popup2" />
            </S.Classification>
            <S.Classification>
              복지몰
              <img src="/section4_popup4.png?v=1" alt="section4_popup4" />
            </S.Classification>
            <S.Classification>
              면세점
              <img src="/section4_popup5.png?v=1" alt="section4_popup5" />
            </S.Classification>
            <S.Classification>
              SNS
              <br />
              공동구매
              <img src="/section4_popup3.png?v=1" alt="section4_popup3" />
            </S.Classification>
            <S.Classification>
              종합몰
              <img src="/section4_popup1.png?v=1" alt="section4_popup1" />
            </S.Classification>
          </div>
        </section>
      </S.Content>
    </S.Section4>
  );
};

export default Section4;
