import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

import Modal from '../../shared/components/Modal';

type State = {
  isModalOpened: boolean;
};

const Section8 = () => {
  const [isModalOpened, setIsModalOpened] =
    useState<State['isModalOpened']>(false);
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);

  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 4870) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section8>
      <Modal
        isOpened={isModalOpened}
        header="솔루션 소개"
        onClose={() => {
          setIsModalOpened(false);
        }}
        size="large"
      >
        <S.ModalContainer>
          <img src="/section8_popup1.png?v=1" alt="/section8_popup1.png?v=1" />
          <img src="/section8_popup2.png?v=1" alt="/section8_popup2.png?v=1" />
          <img src="/section8_popup3.png?v=1" alt="/section8_popup3.png?v=1" />
          <img src="/section8_popup4.png?v=1" alt="/section8_popup4.png?v=1" />
          <img src="/section8_popup5.png?v=1" alt="/section8_popup5.png?v=1" />
        </S.ModalContainer>
      </Modal>
      <S.PContent>
        <div>저스트큐는</div>
        <div>
          <span className="color bold">
            9년 간 60만가지 이상의 상품을 판매한 노하우
          </span>
          와
        </div>
        <div>누적 10억 개의 커머스 데이터를 통해 각 국가마다</div>
        <div>
          <span className="bold" style={{ position: 'relative' }}>
            <motion.img
              src="/boldHight.png?v=1"
              alt="/boldHight.png?v=1"
              style={{
                width: '700px',
                height: '24px',
                position: 'absolute',
                bottom: '0px',
                left: '-10px',
              }}
              initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
              animate={
                isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
              }
              transition={{ duration: 1, ease: 'easeOut' }}
            />
            가장 최적화(SEO), 현지화된 정보로 판매
          </span>
          합니다.
        </div>
      </S.PContent>
      <S.ImageContent>
        <S.ImageContainer>
          <S.ImageItem>
            <div>{S.Image1}</div>
            <div>
              40개 이상의
              <br />
              마켓 입점 가능
            </div>
          </S.ImageItem>
          <S.ImageItem>
            <div>{S.Image2}</div>
            <div>수 십개의 채널 통합관리</div>
          </S.ImageItem>
          <S.ImageItem>
            <div>{S.Image3}</div>
            <div>
              데이터 분석 기반으로
              <br />
              최적화한 판매
            </div>
          </S.ImageItem>
          <S.ImageItem>
            <div>{S.Image4}</div>
            <div>
              전략적 제휴를
              <br />
              통합 판매 촉진
            </div>
          </S.ImageItem>
          <S.ImageItem>
            <div>{S.Image5}</div>
            <div>
              판매 편의
              <br />
              부가서비스 제공
            </div>
          </S.ImageItem>
          <div className="goPopup" onClick={() => setIsModalOpened(true)}>
            자세히 보기
          </div>
        </S.ImageContainer>
      </S.ImageContent>
    </S.Section8>
  );
};

export default Section8;
