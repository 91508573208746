import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section12 = () => {
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);

  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 7850) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  return (
    <S.Section12>
      <S.Header>
        상품 판매에 관심있는 누구든지
        <br />
        <span style={{ position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight.png?v=1"
            style={{
              width: '620px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-5px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          저스트큐와 함께 더 크게 꿈꾸세요
        </span>
      </S.Header>
      <S.Items>
        <S.Item>
          <div className="imageContainer">
            <img
              className="image"
              src="/section12_item1.png?v=1"
              alt="/section12_item1.png?v=1"
            />
            <div className="text">브랜드사</div>
          </div>
        </S.Item>
        <S.Item>
          <div className="imageContainer">
            <img
              className="image"
              src="/section12_item2.png?v=1"
              alt="/section12_item2.png?v=1"
            />
            <div className="text">제조사(D2C)</div>
          </div>
        </S.Item>
        <S.Item>
          <div className="imageContainer">
            <img
              className="image"
              src="/section12_item3.png?v=1"
              alt="/section12_item3.png?v=1"
            />
            <div className="text">
              {' '}
              오프라인 매장만
              <br />
              운영하는 사업자
            </div>
          </div>
        </S.Item>
        <S.Item>
          <div className="imageContainer">
            <img
              className="image"
              src="/section12_item4.png?v=1"
              alt="/section12_item4.png?v=1"
            />
            <div className="text">병행수입자</div>
          </div>
        </S.Item>
        <S.Item>
          <div className="imageContainer">
            <img
              className="image"
              src="/section12_item5.png?v=1"
              alt="/section12_item5.png?v=1"
            />
            <div className="text">초보 창업자</div>
          </div>
        </S.Item>
      </S.Items>
    </S.Section12>
  );
};

export default Section12;
