import styled from 'styled-components';

export const Section6 = styled.div`
  background-color: #ffffff;

  width: 100%;

  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: min(calc(33.58vw + 105.29px), 797px);

  margin-bottom: max(0, calc(-0.02vw + 41.1px));
  background-color: rgba(18, 91, 77, 1);

  img {
    width: 60%;
    height: auto;
    position: absolute;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: min(calc(3.21vw + 18.35px), 80px);
  letter-spacing: -0.02em;
  color: #fff;
  & > div:first-of-type,
  & > div:nth-of-type(2) {
    font-weight: 500;

    margin-bottom: calc(0.2vw + 1.14vh);
  }
  & > div:nth-of-type(3) {
    z-index: 99;
    font-weight: 700;

    & > span {
      background-color: #02a480;
    }
  }
`;
