import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Section3 = styled.div`
  background-color: #f3f3f3;

  height: calc(7.63vh + 567.56px);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section3Header = styled.div`
  text-wrap: nowrap;
  height: calc(1.87vw + 94.04px);

  margin-top: 7vh;

  font-size: calc(1.69vw + 14.09px);
  font-weight: 300;
  line-height: calc(1.67vw + 32.89px);
  letter-spacing: -0.02em;
  text-align: center;
`;

export const Section3BalloonContainer = styled.div`
  width: calc(40.33vw + 235.61px);
  height: 50%;
  position: relative;
  margin-top: calc(3.61vw - 15.35px);

  @media (max-width: 425px) {
    width: 82.35%;

    display: flex;
    flex-direction: column;
  }
`;

export const Section3Balloon = styled(motion.div)`
  position: absolute;

  background-color: #ffffff;

  font-size: calc(0.28vw + 16px);
  font-weight: 300;
  letter-spacing: -0.02em;
  text-align: center;

  padding: calc(0.35vw + 8px) calc(1.6vw + 4px);
  border-radius: 10vw;

  box-shadow: 3px 6px 15px 0px #0000001a;

  & > span {
    font-weight: 600;
  }

  &:nth-of-type(1) {
    top: 0;
    left: 10%;
  }
  &:nth-of-type(2) {
    top: 5%;
    right: 10%;
  }
  &:nth-of-type(3) {
    top: 33%;
    right: 30%;
  }
  &:nth-of-type(4) {
    left: 0;
    top: 50%;
  }
  &:nth-of-type(5) {
    top: 50%;
    right: 0;
  }
  &:nth-of-type(6) {
    top: 75%;
    left: 25%;
  }
  &:nth-of-type(7) {
    right: 22.5%;
    bottom: 0;
  }

  @media (max-width: 1200px) {
    &:nth-of-type(7) {
      right: 16.5%;
    }
  }
  @media (max-width: 1024px) {
    &:nth-of-type(3) {
      top: 31%;
    }
  }
  @media (max-width: 980px) {
    &:nth-of-type(6) {
      top: 70%;
    }
    &:nth-of-type(7) {
      right: 15%;
      bottom: 5%;
    }
  }
  @media (max-width: 670px) {
    &:nth-of-type(1) {
      left: 5%;
    }
    &:nth-of-type(2) {
      right: 5%;
    }
    &:nth-of-type(3) {
      top: 27.5%;
      right: 25%;
    }
    &:nth-of-type(6) {
      left: 17.5%;
    }
  }
  @media (max-width: 590px) {
    &:nth-of-type(7) {
      right: 10%;
    }
  }
  @media (max-width: 425px) {
    position: initial;

    &:nth-of-type(odd) {
      margin-right: auto;
    }
    &:nth-of-type(even) {
      margin-left: auto;
    }
    & + & {
      margin-top: 1vh;
    }
  }
`;
