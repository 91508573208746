import * as S from './styles';

import React, { useEffect, useMemo, useState } from 'react';
import { motion, useScroll } from 'framer-motion';

const Section10 = () => {
  const { scrollY } = useScroll();
  const [isHight, setIsHight] = useState(false);
  useEffect(() => {
    return scrollY.on('change', (latest) => {
      if (latest > 6250) {
        setIsHight(true);
      }
    });
  }, [scrollY]);
  const interviews = useMemo<
    {
      header: string;
      content: JSX.Element | string;
    }[]
  >(() => {
    return [
      {
        header: '저스트큐',
        content:
          '저스트큐를 도입한 후, 판매채널별로 상품 데이터를 개별적으로 관리해야 했던 불편함이 해소되었습니다. 하나의 관리 페이지에서 다양한 판매채널을 통합적으로 운영할 수 있어서 운영 효율성이 크게 향상되었습니다.',
      },
      {
        header: '저스트큐',
        content:
          'AI와 빅데이터를 활용한 판매채널 상위 노출은 우리의 유입 및 구매전환율을 증가시켰습니다. 저스트큐의 자동화된 기능을 통해 효과적인 키워드 및 광고 세팅을 할 수 있었고, 이는 매출 증대로 이어졌습니다.',
      },
      {
        header: '저스트큐',
        content:
          '우리는 저스트큐를 통해 다양한 판매채널에 빠르게 입점할 수 있는 점과 상품 데이터를 한 번에 통합 관리할 수 있는 편리함을 가장 큰 장점으로 꼽습니다. 또한, AI 기능을 활용해 우리 상품의 가격과 경쟁력을 파악하는 데에도 도움을 받고 있습니다.',
      },
      {
        header: '저스트큐',
        content:
          '저스트큐 유통 솔루션을 도입한 후 우리의 판매 증가와 브랜드 인지도 향상을 확인할 수 있었습니다. 특히, 저스트큐의 통합 관리와 빅데이터 활용으로 인해 우리는 타겟 시장에 보다 적합한 상품 라인업을 구성하고 경쟁사와 차별화된 전략을 세울 수 있었습니다.',
      },
    ];
  }, []);
  const [interviewIdx, setInterviewIdx] = useState<number>(0);

  return (
    <S.Section10>
      <S.Header>
        여러분께{' '}
        <span style={{ position: 'relative' }}>
          {' '}
          <motion.img
            src="/boldHight.png?v=1"
            alt="/boldHight"
            style={{
              width: '175px',
              height: '24px',
              position: 'absolute',
              bottom: '0px',
              left: '-5px',
            }}
            initial={{ opacity: 0, clipPath: 'inset(0 100% 0 0)' }}
            animate={
              isHight ? { opacity: 1, clipPath: 'inset(0 0 0 0)' } : undefined
            }
            transition={{ duration: 1, ease: 'easeOut' }}
          />
          저스트큐
        </span>
        란
      </S.Header>
      <S.ItemContainer>
        <S.Item>
          {S.Item1Icon}
          <div>신뢰</div>
        </S.Item>
        <S.Item>
          {S.Item2Icon}
          <div>상생</div>
        </S.Item>
        <S.Item>
          {S.Item3Icon}
          <div>전문성</div>
        </S.Item>
        <S.Item>
          {S.Item4Icon}
          <div>혁신</div>
        </S.Item>
      </S.ItemContainer>
      <S.BottomSection>
        <S.Interview>
          <S.InterviewHeader>
            {interviews[interviewIdx].header}
          </S.InterviewHeader>
          <S.InterviewContent>
            {interviews[interviewIdx].content}
          </S.InterviewContent>
          <S.InterviewButtons>
            <div
              onClick={() => {
                interviewIdx !== 0 && setInterviewIdx(interviewIdx - 1);
              }}
            >
              {S.InterviewPrevButton}
            </div>
            <div
              onClick={() => {
                interviewIdx !== interviews.length - 1 &&
                  setInterviewIdx(interviewIdx + 1);
              }}
            >
              {S.InterviewNextButton}
            </div>
          </S.InterviewButtons>
        </S.Interview>
        <S.Photos>
          <S.Photo>
            <img src="/section10_photo1.png?v=1" alt="section10_photo1" />
          </S.Photo>
          <S.Photo>
            <img src="/section10_photo2.png?v=1" alt="section10_photo2" />
          </S.Photo>
          <S.Photo>
            <img src="/section10_photo2.png?v=1" alt="section10_photo2" />
          </S.Photo>
        </S.Photos>
      </S.BottomSection>
    </S.Section10>
  );
};

export default Section10;
