import styled from 'styled-components';

export const Section5 = styled.div`
  background-color: #1ead8e;

  height: calc(27.62vw + 46.02px);

  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 40%;
    max-width: 1800px;
    z-index: 10;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  position: relative;

  & > svg:first-of-type {
    position: absolute;
    top: 13.651rem;
    left: 45rem;
    width: 5.5vw;
  }
  & > svg:nth-of-type(2) {
    position: absolute;
    top: 16rem;
    left: 48.3rem;
    width: 3vw;
  }
  & > svg:nth-of-type(3) {
    position: absolute;
    top: 10.4rem;
    left: 51.3rem;
    width: 12vw;
  }
  & > svg:nth-of-type(4) {
    position: absolute;
    top: 11rem;
    left: 45.5rem;
    width: 6vw;
  }
  /* & > svg:nth-of-type(5) {
    position: absolute;
    top: 25.651rem;
    left: 66.375rem;
    width: 12vw;
  } */
`;
export const Classification = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  &:hover {
    & > img {
      animation-name: fadeIn;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
  @keyframes fadeIn {
    to {
      scale: 1.2;
    }
  }

  & > img {
    z-index: 20;
    scale: 0.8;

    position: absolute;

    width: 200px;
    @media (max-width: 960px) {
      width: 179.2px;
    }
  }

  &:first-of-type {
    top: 35%;
    left: 35%;
    @media (max-width: 1150px) {
      top: 28%;
      left: 25%;
    }
    @media (max-width: 960px) {
      top: 25%;
      left: 20%;
    }
    @media (max-width: 700px) {
      top: 10%;
      & > img {
        left: 110%;
      }
    }
    @media (max-width: 620px) {
      top: 5%;
      right: 7%;
      & > img {
        left: 80%;
        top: unset;
        bottom: 110%;
        width: 125px;
      }
    }
  }
  &:nth-of-type(2) {
    top: 55%;
    left: 34%;
    /* & > img {
      width: 100%;
    } */

    @media (max-width: 1150px) {
      right: -1%;
    }
    @media (max-width: 960px) {
      right: -7%;
      & > img {
        right: -200%;
      }
    }
    @media (max-width: 800px) {
      & > img {
        top: 100%;
      }
    }
    @media (max-width: 620px) {
      top: 40%;
      right: 0%;
      & > img {
        right: unset;
        left: 120%;
        top: 50%;
        width: 125px;
      }
    }
  }
  &:nth-of-type(3) {
    top: 18%;
    left: 39%;
    & > img {
      left: 100%;
      top: 40%;
    }

    @media (max-width: 1150px) {
      bottom: 5%;
    }
    @media (max-width: 960px) {
      bottom: 2%;
    }
    @media (max-width: 800px) {
      bottom: 10%;
    }
    @media (max-width: 700px) {
      bottom: 13%;
    }
    @media (max-width: 620px) {
      bottom: 2%;
      left: 40%;
      & > img {
        left: -40%;
        top: 120%;
        width: 125px;
      }
    }
    @media (max-width: 380px) {
      & > img {
        left: 50%;
      }
    }
  }
  &:nth-of-type(4) {
    top: 55%;
    left: 47%;
    & > img {
      right: 100%;
      top: -100%;
    }

    @media (max-width: 1150px) {
      left: 5%;
    }
    @media (max-width: 960px) {
      left: 1%;
    }
    @media (max-width: 800px) {
      bottom: 30%;
      left: 6%;
    }
    @media (max-width: 700px) {
      left: 0;
    }
    @media (max-width: 620px) {
      bottom: 23%;
      left: 4%;
      & > img {
        right: 40%;
        top: 115%;
        width: 125px;
      }
    }
    @media (max-width: 380px) {
      & > img {
        right: 20%;
        top: 125%;
      }
    }
  }
  &:nth-of-type(5) {
    top: 17%;
    left: 32%;
    & > img {
      left: -170%;
      bottom: 90%;
      width: 200px;
    }

    @media (max-width: 1300px) {
      top: 14%;
      left: 25%;
    }
    @media (max-width: 1150px) {
      left: 17.5%;
    }
    @media (max-width: 960px) {
      top: 15%;
      left: 10%;
      & > img {
        width: 195.2px;
        left: -230%;
      }
    }
    @media (max-width: 800px) {
      & > img {
        left: -260%;
      }
    }
    @media (max-width: 700px) {
      left: 5%;
    }
    @media (max-width: 620px) {
      top: 13%;
      left: 15%;
      & > img {
        left: -110%;
        bottom: 110%;
        width: 125px;
      }
    }
  }

  @media (max-width: 800px) {
    width: 80px;
    height: 80px;

    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 620px) {
    width: 66px;
    height: 66px;

    font-size: 16px;
    line-height: 20px;

    background-color: #1ead8e;
    & > img {
      display: block;

      scale: 1;
      opacity: 1;
    }

    &:hover {
      & > img {
        animation-name: unset;
      }
    }
  }
  &:nth-of-type(5) {
    top: 65%;
    left: 54%;

    @media (max-width: 1300px) {
      top: 14%;
      left: 25%;
    }
    @media (max-width: 1150px) {
      left: 17.5%;
    }
    @media (max-width: 960px) {
      top: 15%;
      left: 10%;
      & > img {
        width: 195.2px;
        left: -230%;
      }
    }
    @media (max-width: 800px) {
      & > img {
        left: -260%;
      }
    }
    @media (max-width: 700px) {
      left: 5%;
    }
    @media (max-width: 620px) {
      top: 13%;
      left: 15%;
      & > img {
        left: -110%;
        bottom: 110%;
        width: 125px;
      }
    }
  }

  @media (max-width: 800px) {
    width: 80px;
    height: 80px;

    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: 620px) {
    width: 66px;
    height: 66px;

    font-size: 16px;
    line-height: 20px;

    background-color: #1ead8e;
    & > img {
      display: block;

      scale: 1;
      opacity: 1;
    }

    &:hover {
      & > img {
        animation-name: unset;
      }
    }
  }
`;
