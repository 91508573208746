import styled from "styled-components";

export const Section7 = styled.div`
  background-image: url("/section7.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: max(min(calc(16.32vw + 230.64px), 544px), 300px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  letter-spacing: -0.02em;

  & > div:first-of-type {
    font-size: max(min(calc(6vw - 1.5px), 48px), 16px);
    font-weight: 300;
    line-height: max(min(calc(8.25vw - 3.0625px), 65px), 32px);

    margin-bottom: max(min(calc(0.5vw - 0.125vh), 4vh), 2vh);

    & > span {
      font-weight: 700;
    }
  }
  & > div:nth-of-type(2) {
    font-size: max(min(calc(6vw - 1.5px), 48px), 16px);
    font-weight: 700;
    line-height: max(min(calc(8.25vw - 3.0625px), 65px), 32px);

    margin-bottom: max(min(calc(0.25vw - 0.0625vh), 2vh), 1vh);
  }
  & > div:nth-of-type(3) {
    font-size: min(calc(0.5vw + 12.4px), 22px);
    font-weight: 300;
    line-height: min(calc(3vw + 11.25px), 36px);
    letter-spacing: -0.02em;
    text-align: center;

    & > span {
      font-weight: 700;
    }
  }
`;
