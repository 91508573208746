import styled from 'styled-components';

export const Section9 = styled.div`
  height: min(calc(5.35vw + 697.26px), 800px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PContent = styled.div`
  font-size: min(calc(1.87vw + 12px), 48px);
  font-weight: 400;
  line-height: min(calc(2.21vw + 22.62px), 65px);
  letter-spacing: -0.02em;
  text-align: center;

  & > span {
    font-weight: 700;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 4vh;
  margin-bottom: 9vh;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
    align-items: center;

    margin-bottom: 4vh;
  }
`;

export const Section = styled.div`
  width: 586px;
  height: 200px;

  border: 1px solid #1ead8e;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  color: #000e33;

  & + & {
    margin-left: 2vw;
  }

  /* @media (hover: hover) {
    &:hover {
      color: #ffffff;
      background-color: #1ead8e;
      border-color: #1ead8e;
    }
  } */

  @media (max-width: 768px) {
    width: 85%;
    height: 13vh;

    padding: 2%;

    & + & {
      margin-top: 2vh;
      margin-left: 0;
    }
  }
`;

export const SectionTitle = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(calc(0.25vw + 17.2px), 22px);
  font-weight: 700;

  letter-spacing: -0.02em;

  margin-bottom: 8px;

  & > span {
    font-size: min(calc(0.31vw + 21px), 27px);
    font-weight: 600;
    color: #1ead8e;
  }
`;

export const SectionContent = styled.div`
  text-align: center;
  font-size: min(calc(0.25vw + 13.2px), 18px);
  font-weight: 400;
  line-height: min(calc(0.5vw + 20.4px), 30px);
  letter-spacing: -0.02em;
  word-break: keep-all;

  & > span {
    font-size: min(calc(0.31vw + 21px), 18px);
    font-weight: 600;
    color: #1ead8e;
  }
`;
